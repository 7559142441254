.cooperationPage{
    .sh-inban{
        .sh-des{
            margin-top: 25px;
            @media (max-width:767px) {
                margin-top: 20px;
            }
        }
    }
    .item1{
        padding: 120px 0;
        @media (max-width:991px) {
            padding: 80px 0;
            padding-bottom: 105px;
        }
        .it1-bx{
            ul{
                li{
                    position: relative;
                }
                li a{
                    display: flex;
                    position: relative;
                    @media (max-width:991px) {
                        display: block;
                    }
                    .img-bx{
                        width: 74.3%;
                        margin-left: auto;
                        @media (max-width:991px) {
                            width: 100%;
                        }
                        .bgcover{
                            padding-bottom: 320px;
                            background-size:  cover !important;
                            @media (max-width:991px) {
                                padding-bottom: 35%;
                            }
                        }
                    }
                    .card{
                        width: 390px;
                        height: 177px;
                        background-color: #e5e5e5;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        padding: 40px 50px;
                        @media (max-width:991px) {
                            position: relative;
                            top: auto;
                            transform: none;
                            width: 100%;
                            padding: 25px 24px;
                            height: 130px;
                        }
                        .ti{
                            @include fontset(24,1.25,2.4,500,#000);
                            height: 60px;
                            position: relative;
                            overflow: hidden;
                            @media (max-width:991px) {
                                font-size: pxToEm(20);
                                height: auto;
                                max-height: 53px;
                                margin-bottom: 21px;
                            }
                        }
                        .redmore{
                            @include fontset(16,1,.8,400,#000);
                            padding-bottom: 17px;
                            position: relative;
                            border-bottom: 1px solid rgba(#000000 , .3);
                            display: inline-block;
                            @media (max-width:991px) {
                                padding-bottom: 15px;
                            }
                            &::before{
                                position: absolute;
                                content: '';
                                left: 0;
                                bottom: 0;
                                width: 0;
                                height: 4px;
                                background-color: $hover;
                                transition: .4s;
                            }
                        }
                    }
                    @media (min-width:768px) {
                        &:hover{
                            .card .redmore{
                                color: $hover;
                            }
                            .card .redmore::before{
                                width: 100%;
                            }
                            .card .ti{
                                color: $hover;
                            }
                        }
                    }
                }
                @media (min-width:992px) {
                    li:nth-child(odd){
                        a{
                            .img-bx{
                                margin-left: 0;
                            }
                            .card{
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                }
                
                li:not(:last-child){
                    margin-bottom: 95px;
                    @media (max-width:991px) {
                        margin-bottom: 20px;
                    }
                }
                li:first-child,li:nth-child(3){
                    z-index: 10;
                }
                li:nth-child(4){
                    z-index: 9;
                }
                li:first-child::after{
                    position: absolute;
                    content: '';
                    right: -270px;
                    top: 68%;
                    transform: translateY(-50%);
                    width: 735px;
                    height: 600px;
                    background: url(../images/bg2.png) no-repeat center center / contain;
                    z-index: -1;
                    @media (max-width:991px) {
                        top: 16%;
                        width: 445px;
                        height: 350px;
                    }
                }
                li:nth-child(2)::after{
                    position: absolute;
                    content: '';
                    left: -270px;
                    top: 68%;
                    transform: translateY(-50%);
                    width: 735px;
                    height: 600px;
                    background: url(../images/bg2.png) no-repeat center center / contain;
                    z-index: -1;
                    pointer-events: none;
                    @media (max-width:991px) {
                        width: 445px;
                        height: 350px;
                        top: 6%;
                        left: -290px;
                    }
                }
                li:nth-child(4)::after{
                    position: absolute;
                    content: '';
                    right: -270px;
                    top: 68%;
                    transform: translateY(-50%);
                    width: 735px;
                    height: 600px;
                    background: url(../images/bg2.png) no-repeat center center / contain;
                    z-index: -1;
                    pointer-events: none;
                    @media (max-width:991px) {
                        top: 16%;
                        width: 445px;
                        height: 350px;
                    }
                }
                li:nth-child(5)::after{
                    position: absolute;
                    content: '';
                    left: -270px;
                    top: 68%;
                    transform: translateY(-50%);
                    width: 735px;
                    height: 600px;
                    background: url(../images/bg2.png) no-repeat center center / contain;
                    z-index: -1;
                    pointer-events: none;
                    @media (max-width:991px) {
                        width: 445px;
                        height: 350px;
                        top: 6%;
                        left: -290px;
                    }
                }
            }
        }
    }
}


.cooperationdetailPage{
    .sh-inban{
        .sh-des{
            margin-top: 25px;
            @media (max-width:767px) {
                margin-top: 20px;
            }
        }
    }
    .d-bg1{
        position: absolute;
        left: 0;
        bottom: 0px;
        background: url(../images/bg1.png);
        width: 285px;
        height: calc(100% - 647px);
        z-index: -1;
        @media (max-width:767px) {
            display: none;
        }
    }
    .d-bg2{
        position: absolute;
        right: 0;
        bottom: 0px;
        background: url(../images/bg1.png);
        width: 590px;
        height: calc(100% - 852px);
        z-index: -1;
        @media (max-width:767px) {
            display: none;
        }
    }
    .item1{
        padding: 120px 0;
        position: relative;
      
        @media (max-width:991px) {
            padding: 80px 0;
            padding-bottom: 75px;
            overflow: hidden;
        }
        .editor_Content{
            position: relative;
            @media (max-width:767px) {
                &::before{
                    position: absolute;
                    content: '';
                    background: url(../images/bg1.png);
                    background-size: contain;
                    width: 319px;
                    height: 251px;
                    bottom: -56px;
                    right: -200px;
                    z-index: -1;
                }
            }
        }
        .sh-lti{
            margin-bottom: 45px; 
            @media (max-width:991px) {
                margin-bottom: 15px;
            }  
            span{
                display: block;
            }
        }
        .back-btn{
            display: block;
            margin: 0 auto;
            margin-top: 105px;
            width: 185px;
            height: 61px;
            border: solid 1px #a0a0a0;
            text-align: center;
            @include fontset(16,3.8,.8,300,#000);
            position: relative;
            &::before{
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 0;
                height: 4px;
                background-color: $hover;
                transition: .4s ease;
            }
            @media (min-width:768px) {
                &:hover{
                    &::before{
                        width: 100%;
                    }
                }
            }
            @media (max-width:991px) {
                margin-top: 65px;
                width: 120px;
                height: 40px;
                line-height: 2.5;
            }
        }
    }
}


.newsPage{
    .sec-bx{
        position: relative;
        background: url(../images/news/bg_point.png);
        background-repeat: repeat-y;
        @media (max-width:767px){
            background: none;
        }
    }
    .item1{
        padding-top: 110px;
        @media (max-width:767px){
            padding-top: 70px;
        }
    }
    .item2{
        margin-top: 155px;
        padding-bottom: 130px;
        @media (max-width:767px){
            margin-top: 50px;
            padding-bottom: 100px;
        }
        .news-list{
            li:nth-child(1){
                position: relative;
                &::after{
                    position: absolute;
                    content: '';
                    left: 54%;
                    transform: translateX(-50%);
                    top: 79%;
                    width: 100vw;
                    background: url(../images/news/monewbg.png) no-repeat center;
                    height: 520px;
                    z-index: -1;
                    background-size: contain;
                    pointer-events: none;
                }   
            }
        }
    }
}

.newsdetailPage{
    .d-bg1{
        position: absolute;
        left: 0;
        bottom: 0px;
        background: url(../images/bg1.png);
        width: 14.8%;
        height:1010px;
        z-index: -1;
        @media (max-width:767px) {
            display: none;
        }
    }
    .item1{
        padding-top: 105px;
        position: relative;
        @media (max-width:767px) {
            padding-top: 80px;
        }
        &::before{
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            width: 30.7%;
            height: 834px;
            background: url(../images/bg1.png);
            @media (max-width:767px) {
                display: none;
            }
        }
    }
    .item2{
        padding: 120px 0;
        padding-top: 100px;
        position: relative;
        @media (max-width:767px) {
            padding-top: 52px;
            padding-bottom: 100px;
        }
        .it2-bx{
            max-width: 1017px;
            width: 100%;
            margin: 0 auto;
            .ti-bx{
                padding-left: 16px;
                position: relative;
                margin-bottom: 42px;
                &::before{
                    position: absolute;
                    content: '';
                    top: 6px;
                    left: 0;
                    width: 1px;
                    height: 91%;
                    background-color: $hover;
                }
                .da{
                    @include fontset(16,1.8,.8,400,$hover);
                }
                .ti{
                    @include fontset(24,1.5,2.4,500,#000);
                    @media (max-width:767px) {
                        font-size: pxToEm(20);
                        line-height: 1.3;
                        letter-spacing: .5px;
                    }
                }
            }
        }
        
        .editor_Content{
            position: relative;
        }
        .sh-lti{
            margin-bottom: 45px; 
            @media (max-width:991px) {
                margin-bottom: 15px;
            }  
            span{
                display: block;
            }
        }
        .back-btn{
            display: block;
            margin: 0 auto;
            margin-top: 105px;
            width: 185px;
            height: 61px;
            border: solid 1px #a0a0a0;
            text-align: center;
            @include fontset(16,3.8,.8,300,#000);
            position: relative;
           
            &::before{
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 0;
                height: 4px;
                background-color: $hover;
                transition: .4s ease;
            }
            @media (min-width:768px) {
                &:hover{
                    &::before{
                        width: 100%;
                    }
                }
            }
            @media (max-width:991px) {
                margin-top: 35px;
                width: 120px;
                height: 40px;
                line-height: 2.5;
            }
        }
    }
}


.faqPage{
    .item1{
        padding-top: 105px;
        @media (max-width:767px) {
            padding-top: 73px;
        }
        #top-menu-ul2{
            margin-top: 14px;
            position: relative;
            z-index: 1;
            padding: 0 50px;
            @media (max-width: 767px){
                padding: 0 27px;
            }
            .item_Menu{
                margin: 0 auto;   
                overflow: hidden;    
                height: 30px;
            }
            .item_menu_Box{    
                width:100%;
                overflow:hidden;
                overflow-x: scroll;
                position: relative;
                text-align:center;
                .item_menu_list{
                    white-space: nowrap !important;
                    padding-bottom: 0px;
                    text-align: center;
                    font-size:0px;
                    display:inline-flex; 
                }
        
                li{
                    height: 30px;
                }
                li a{
                    position: relative;
                    display: block;
                    line-height: 1;
                    letter-spacing: 0.6px;
                    font-size: pxToEm(18);
                    color: #000000;
                    @media (max-width:767px) {
                        padding:7px 0 3px;
                        margin:0 50px 0 0; 
                    }
                    @media (min-width:768px) {
                        margin:0 25px; 
                        padding:7px 0;
                        &:hover{
                            color:$hover;
                        }
                    }
                  
                }
                li.active a{
                    color:$hover;
                }
            }
        }
    }
    .item2{
        margin-top: 70px;
        padding-bottom: 160px;
        @media (max-width:767px) {
            margin-top: 40px;
            padding-bottom: 110px;
        }
        .faq-list {
            position: relative;
            &::before{
                position: absolute;
                content: '';
                right: -180px;
                top: -120px;
                background: url(../images/bg2.png) no-repeat center center / contain;
                width: 630px;
                height: 520px;
                pointer-events: none;
                @media (max-width:767px) {
                    width: 330px;
                    height: 220px;
                    top: 0;
                    background-size: cover;
                }
            }
            &::after{
                position: absolute;
                content: '';
                left: -170px;
                top: 90px;
                background: url(../images/bg2.png) no-repeat center center / contain;
                width: 630px;
                height: 520px;
                z-index: -1;
                @media (max-width:767px) {
                    width: 330px;
                    height: 220px;
                    background-size: cover;
                    left: -200px;
                    top: 210px;
                }
            }
        }
        .faq-list li .ti{
            background-color: #e6e6e6;
            position: relative;
            align-items: center;
            &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                width: 210px;
                height: 100%;
                background: url(../images/faq/faqbg.png);
                pointer-events: none;
                @media (max-width:767px) {
                    background-size: 215px;
                    width: 105px;
                }
            }
        }
        .faq-list li .ti .des{
            color: $hover;
            font-weight: 500;
            font-size: pxToEm(24);
            position: relative;
            top: 2px;
            @media (max-width:767px) {
                font-size: pxToEm(20);
                top: auto;
            }
        }
        .faq-list li .ti .remore{
            color: #000;
            &::before,&::after{
                background-color: #000;
            }
        }
        .faq-list li .ti .num{
            min-width: 61px;
            margin-right: 10px;
            span{
                font-size: pxToEm(48);
                @media (max-width:767px) {
                    font-size: pxToEm(30);
                }
            }
        }
        .faq-list li .card{
            @media (max-width:767px) {
                width: calc(100% + 48px);
                left: -24px;
                position: relative;
                margin-top: -47px;
                padding-top: 85px;
            }
        }
        .faq-list li .ti .remore{
            top: 50%;
            transform: translateY(-50%);
            @media (max-width:767px) {
                transform: none;
                top: 20px;
            }
        }
    }
}