.aboutPage{
    .item1{
        margin-top: 180px;
        padding-bottom: 95px;
        @media (max-width:1199px) {
            margin-top: 90px;
        }
        @media (max-width:767px) {
            margin-top: 50px;
            padding-bottom: 0;
        }
        .l-ti{
            @include fontset(24,1.5,2.4,500,#000);
            text-align: center;
            @media (max-width:767px) {
                font-size: pxToEm(20);
                text-align: left;
                margin-top: 8px;
                margin-bottom: 20px;
            }
            span{
                padding-left: 17px;
                position: relative;
                @media (max-width:767px) {
                    padding-left: 12px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 3px;
                    width: 1px;
                    height: 83%;
                    background-color: #000;
                }
            }
        }
        .it1-bx{
            margin-top: 30px;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 3;
            @media (max-width:991px) {
                display: block;
            }
            .l{
                width: 40%;
                @media (max-width:991px) {
                    width: 100%;
                }
                .bgcover{
                    padding-bottom: 271px;
                    background-size: cover !important;
                    @media (max-width:767px) {
                        padding-bottom: 55%;
                    }
                }
            }
            .r{
                width: 60%;
                padding-left: 3%;
                padding-top: 4.5%;
                position: relative;
                height: 271px;
                @media (max-width:991px) {
                    width: 100%;
                    height: auto;
                    padding-bottom: 40px;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                @media (max-width:570px) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0;
                    background-color: #fff;
                    width: 70vw;
                    height: 100%;
                    @media (max-width:991px) {
                        width: 100%;
                    }
                    @media (max-width:570px) {
                        width: 100vw;
                        left: -24px;
                    }
                }
                .sh-des{
                    position: relative;
                    z-index: 1;
                }
                .des1{
                    margin-bottom: 40px;
                    @media (max-width:1199px) {
                        margin-bottom: 12px;
                    }
                    @media (max-width:767px) {
                        margin-bottom: 30px;
                    }
                }
                .des2{
                    span{
                        font-weight: 700;
                        color: $hover;
                    }
                }
            }
        }
    }
    .item2{
        padding-top: 130px;
        margin-bottom: 100px;
        position: relative;
        @media (max-width:767px) {
            padding-top: 80px;
            margin-bottom: 25px;
        }
        .bg1{
            position: absolute;
            right: 0;
            top: 0;
            width: 40vw;
            height: 100%;
            background: url(../images/bg1.png?1fea62b0);
            @media (max-width:767px) {
                background-size: contain;
                width: 52vw;
                height: 75%;
            }
        }
        // &::before{
        //     position: absolute;
        //     content: '';
        //     right: 0;
        //     top: 0;
        //     width: 40vw;
        //     height: 100%;
        //     background: url(../images/bg1.png?1fea62b0);
        //     @media (max-width:767px) {
        //         background-size: contain;
        //         width: 52vw;
        //         height: 75%;
        //     }
        // }
        .it2-bx{
            display: flex;
            position: relative;
            z-index: 3;
            @media (max-width:991px) {
                display: block;
            }
            .img-bx{
                position: absolute;
                top: 0;
                left: 51%;
                width: 49.1vw;
                @media (max-width:991px) {
                    width: 100%;
                    left: auto;
                    position: relative;
                }
                .bgcover{
                    padding-bottom: 300px;
                    background-size: cover !important;
                    @media (max-width:991px) {
                        padding-bottom: 31.8%;
                    }
                }
            }
            .r{
                width: 48.5%;
                @media (max-width:991px) {
                    width: 100%;
                    margin-top: 37px;
                }
                .des1{
                    margin-bottom: 35px;
                    
                    @media (max-width:991px) {
                        margin-bottom: 31px;
                    }
                    span{
                        color: $hover;
                        font-weight: 700;
                    }
                }
                .des2{
                    margin-bottom: 35px;
                    @media (max-width:991px) {
                        margin-bottom: 31px;
                    }
                }
                .des3{
                    span{
                        font-weight: 700;
                        color: $hover;
                    }
                }
            }
        }
    }
    .item3{
        padding-bottom: 35px;
        padding-top: 80px;
        position: relative;
        @media (max-width:991px) {
            padding-top: 45px;
        }
        .bg1{
            position: absolute;
            left: 0;
            top: 0;
            background: url(../images/bg1.png?1fea62b0);
            width: 34.5vw;
            height: 100%;
            @media (max-width:991px) {
                width: 57vw;
                height: 43%;
                background-size: contain;
            }
        }
        // &::before{
        //     position: absolute;
        //     content: '';
        //     left: 0;
        //     top: 0;
        //     background: url(../images/bg1.png?1fea62b0);
        //     width: 34.5vw;
        //     height: 100%;
        //     @media (max-width:991px) {
        //         width: 57vw;
        //         height: 43%;
        //         background-size: contain;
        //     }
        // }
        .it3-bx{
            display: flex;
            position: relative;
            z-index: 8;
            @media (max-width:991px) {
                display: block;
            }
            .l{
                width: 40%;
                @media (max-width:991px) {
                    width: 100%;
                }
                .bgcover{
                    padding-bottom: 425px;
                    background-size: cover !important;
                    @media (max-width:991px) {
                        padding-bottom: 86%;
                    }
                }
            }
            .r{
                width: 60%;
                @media (max-width:991px) {
                    width: 100%;
                }
                .it3-list{
                    max-width: 610px;
                    padding-left: 10px;
                    width: 100%;
                    margin-left: auto;
                    display: flex;
                    flex-wrap: wrap;
                    @media (max-width:991px) {
                        display: block;
                        margin-top: 25px;
                    }
                    li{
                        width: 50%;
                        display: flex;
                        align-items: center;
                        padding: 27px 0;
                        padding-top: 29px;
                        border-bottom: 1px solid #e5e5e5;
                        @media (max-width:991px) {
                            width: 100%;
                            padding: 12px 0;
                            border: none;
                            &:not(:last-child){
                                border-bottom: 1px solid #e5e5e5;
                            }
                        }
                        @media (min-width:992px) {
                            &:first-child{
                                padding-top: 8px;
                            }
                            &:nth-child(2){
                                padding-top: 8px;
                            }
                            &:last-child{
                                padding-bottom: 8px;
                                border-bottom: none;
                            }
                            &:nth-last-child(2){
                                padding-bottom: 8px;
                                border-bottom: none;
                            }
                            &:nth-child(odd){
                                border-right: 1px solid #e5e5e5;
                            }
                            &:nth-child(even){
                                padding-left: 2%;
                            }
                        }
                        .img-bx{
                            min-width: 62px;
                            width: 62px;
                            margin-right: 20px;
                            @media (max-width:991px) {
                                width: 70px;
                                min-width: 70px;
                                margin-right: 32px;
                            }
                        }
                        .card{
                            .t{
                                @include fontset(20,1.6,.5,500,#000);
                                @media (max-width:991px) {
                                    margin-bottom: 3px;
                                }
                            }
                            .sh-des{
                                .be{
                                    position: relative;
                                    min-width: 106px;
                                    width: 106px;
                                    padding-right: 24px;
                                    margin-right: 5px;
                                    &::before{
                                        position: absolute;
                                        content: '';
                                        right: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        width: 24px;
                                        height: 3px;
                                        background: url(../images/about/arrow.png) no-repeat center center / contain;
                                    }
                                }
                                .af{

                                }
                            }
                        }
                    }
                }
                .ver{
                    max-width: 610px;
                    padding-left: 10px;
                    width: 100%;
                    margin-left: auto;
                    @include fontset(14,2.1,.35,300,#6f6f6f);
                    margin-top: 10px;
                    @media (max-width:991px) {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .item4{
        padding-top: 160px;
        margin-bottom: 75px;
        padding-bottom: 140px;
        @media (max-width:991px) {
            padding-top: 30px;
            padding-bottom: 20px;
        }
        .it4-bx{
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 3;
            @media (max-width:991px) {
                display: block;
            }
            .img-bx{
                position: absolute;
                left: 51%;
                width: 49.1vw;
                @media (max-width:991px) {
                    width: 100%;
                    left: auto;
                    position: relative;
                    margin-bottom: 30px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    bottom: -65px;
                    right: 0;
                    height: 65px;
                    width: 80%;
                    background: #fff;
                    @media (max-width:991px) {
                        bottom: -25px;
                        right: -25px;
                        height: calc(100% + 50px);
                        width: 60%;
                        z-index: -1;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    height: 150px;
                    width: 55%;
                    bottom: -215px;
                    right: 0;
                    background: url(../images/bg1.png?1fea62b0);
                    @media (max-width:991px) {
                        display: none;
                    }
                }
                .bgcover{
                    padding-bottom: 530px;
                    background-size:  cover !important;
                    @media (max-width:991px) {
                        padding-bottom: 56.1%;
                    }
                }
            }
            .card{
                width: 48.5%;
                @media (max-width:991px) {
                    width: 100%;
                }
                .bl{
                    color: $hover;
                    font-weight: 500;
                }
                .sh-des{
                    margin-bottom: 33px;
                    @media (max-width:991px) {
                        margin-bottom: 25px;
                    }
                }
                .des5{
                    margin-bottom: 0;
                }
                .img-bx{
                    @media (max-width:991px) {
                        &::before{
                            display: none;
                        }
                    }
                }
                .bgcover{
                    @media (max-width:991px) {
                        padding-bottom: 56.1%;
                        background-size:  cover !important;
                    }
                }
            }
        }
    }
    .sec-bx{
        position: relative;
        
        .since-bx{
            position: absolute;
            left: 60px;
            top: 70px;
            @media (max-width:991px) {
                display: none;
            }
        }
    }
}


