.indexPage{
    main{
        margin-top: 0;
        @media (max-width:991px) {
            margin-top: 70px;
        }
    }
    .banner{
        position: relative;
        @media (max-width:991px) {
            transform: scale(1.1);
        }
        .in-b{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        img{
            width: 100%;
        }
    }
    .item1{
        background-color: #f2f2f2;
        padding: 120px 0;
        @media (max-width:991px) {
            padding: 60px 0;
            padding-bottom: 45px;
        }
        .ti-flex{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width:991px) {
                display: block;
            }
            .sh-des{
                text-align: right;
                @media (max-width:991px) {
                    text-align: left;
                    margin-top: 30px;
                }
            }
        }
        .it1-list{
            margin-top: 60px;
            display: flex;
            flex-wrap: wrap;
            @media (max-width:991px) {
                margin-top: 30px;
            }
            li{
                @media (min-width:992px) {
                    width: calc((100% - 48px) / 4);
                    &:not(:last-child){
                        margin-right: 16px;
                    }
                }
                @media (max-width:991px) {
                    width: calc((100% - 10px) / 2);
                    margin-bottom: 30px;
                    &:not(:nth-child(even)){
                        margin-right: 10px;
                    }
                }
                
                a{
                    display: block;
                    @media (min-width:768px) {
                        &:hover{
                            .ti{
                                color: $hover;
                            }
                            .img-bx img{
                                transform: scale(1.2);
                            }
                        }
                    }
                    .img-bx{
                        position: relative;
                        overflow: hidden;
                        img{
                            transition: .4s ease;
                            width: 100%;
                        }
                    }
                    .ti{
                        text-align: center;
                        @include fontset(24,1.25,2.4,500,#000);
                        padding: 0 15px;
                        padding-top: 45px;
                        position: relative;
                        @media (max-width:991px) {
                            padding-top: 25px;
                            font-size: pxToEm(20);
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 1px;
                            height: 35px;
                            background-color: #000;
                            @media (max-width:991px) {
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .item2{
        background-color: #fafafa;
        padding: 120px 0;
        padding-bottom: 170px;
        position: relative;
        @media (max-width:991px) {
            padding-top: 75px;
            padding-bottom: 65px;
        }
        .container{
            position: relative;
            z-index: 4;
        }
        .bg-l{
            position: absolute;
            right: 0;
            top: 0;
            width: 75.5%;
            height: 100%;
            background: url(../images/bg1.png);
            z-index: 2;
            @media (max-width:991px) {
                background-size: contain;
                height: 60%;
            }
        }
        .bg-r{
            position: absolute;
            left: 0;
            top: 0;
            width: 47%;
            height: calc(100% - 50px);
            background-color: #fff;
            z-index: 3;
            @media (max-width:991px) {
                height: 45%;
                width: 41%;
            }
        }
        // &::before{
        //     position: absolute;
        //     content: '';
        //     right: 0;
        //     top: 0;
        //     width: 75.5%;
        //     height: 100%;
        //     background: url(../images/bg1.png);
        //     z-index: 2;
        //     @media (max-width:991px) {
        //         background-size: contain;
        //         height: 60%;
        //     }
        // }
        // &::after{
        //     position: absolute;
        //     content: '';
        //     left: 0;
        //     top: 0;
        //     width: 47%;
        //     height: calc(100% - 50px);
        //     background-color: #fff;
        //     z-index: 3;
        //     @media (max-width:991px) {
        //         height: 45%;
        //         width: 41%;
        //     }
        // }
        .ti-flex{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width:991px) {
                display: block;
            }
            .sh-des{
                text-align: right;
                @media (max-width:991px) {
                    text-align: left;
                    margin-top: 25px;
                }
            }
        }
        .it2-list{
            display: flex;
            flex-wrap: wrap;
            margin-top: 60px;
            @media (max-width:991px) {
                margin-top: 15px;
            }
            li.moshow{
                @media (max-width:767px) {
                    .img-bx img{
                        // transform: scale(1.2);
                    }
                    a:before{
                        opacity: .1;
                    }
                }
            }
            li{
                position: relative;
                @media (min-width:992px) {
                    width: calc((100% - 30px) / 3);
                    margin-bottom: 15px;
                    &:not(:nth-child(3n)){
                        margin-right: 15px;
                    }
                }
                @media (max-width:991px) {
                    margin-bottom: 12px;
                }
                @media (min-width:767px) {
                    &:hover{
                        .img-bx img{
                            transform: scale(1.2);
                        }
                        a:before{
                            opacity: 0;
                        }
                    }
                }
                .img-bx{
                    position: relative;
                    overflow: hidden;
                    img{
                        transition: .4s ease;
                    }
                }
                span{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    transform: translate(-50%,-50%);
                    @include fontset(24,1.25,2.4,500,#FFF);
                    padding: 0 15px;
                    text-align: center;
                    z-index: 5;
                    text-shadow :1px 1px 4px black;
                    @media (max-width:991px) {
                        font-size: pxToEm(20);
                    }
                }
                a{
                    display: block;
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #000;
                        opacity: .5;
                        transition: .4s ease;
                        z-index: 3;
                    }
                }
            }
        }
    }
    .item3{
        padding-top: 60px;
        padding-bottom: 110px;
        background-color: #fafafa;
        @media (max-width:991px) {
            padding-top: 0;
            padding-bottom: 75px;
        }
        .container{
            position: relative;
        }
        .bgball{
            background: url(../images/bg2.png) no-repeat center center / contain;
            position: absolute;
            z-index: 0;
            pointer-events: none;
        }
        .bg1{
            top: 0;
            left: -628px;
            width: 530px;
            height: 480px;
            @media (max-width:991px) {
                display: none;
            }
        }
        .bg2{
            width: 767px;
            height: 600px;
            top: -30px;
            right: -218px;
            @media (max-width:991px) {
                width: 380px;
                height: 292px;
                top: 14px;
                right: 55px;
            }
        }
        .bg3{
            width: 800px;
            height: 639px;
            top: 414px;
            left: -237px;
            @media (max-width:991px) {
                left: auto;
                width: 300px;
                height: 259px;
                top: 394px;
                right: -124px;
            }
        }
        .bg4{
            width: 530px;
            height: 486px;
            bottom: -185px;
            right: -490px;
            @media (max-width:991px) {
                display: none;
            }
        }
        .ti-flex{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width:991px) {
                // display: block;
            }
            .sh-allbtn{
                text-align: center;
                display: block;
                width: 148px;
                height: 60px;
                @include fontset(16,3.7,.8,400,#000);
                border: 1px solid rgba(#000,.3);
                transition: .4s ease;
                position: relative;
                @media (max-width:991px) {
                    width: 95px;
                    height: 40px;
                    line-height: 2.5;
                }
                &::before{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 4px;
                    background-color: $hover;
                    content: '';
                    transition: .5s ease;
                }
                @media (min-width:768px) {
                    &:hover{
                        border: 1px solid rgba(#000,.7);
                        color: $hover;
                        &::before{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .news-list{
            margin-top: 100px;
            position: relative;
            z-index: 5;
            @media (max-width:991px) {
                margin-top: 35px;
            }
        }

    }
    .item4{
        background-color: #fafafa;
        padding-bottom: 115px;
        @media (max-width:991px) {
            padding-bottom: 95px;
        }
        .it4-bx{
            display: flex;
            justify-content: space-between;
            margin-top: 55px;
            @media (max-width:1199px) {
                margin-top: 30px;
            }
            @media (max-width:991px) {
                display: block;
            }
            .l{
                width: 50%;
                margin-top: 27px;
                @media (max-width:991px) {
                    width: 100%;
                }
            }
            .r{
                width: 49%;
                @media (max-width:991px) {
                    width: 100%;
                    margin-top: 70px;
                }
            }
        }
        .con-btn{
            display: inline-block;
            padding: 7px 0 ;
            padding-right: 10px;
            position: relative;
            @include fontset(16,1.88,.8,400,#000);
            border-bottom: 1px solid #000;
            transition: .4s ease;
            margin-top: 75px;
            @media (max-width:991px) {
                margin-top: 65px;
                top: 0;
            }
            @media (min-width:768px) {
                &:hover{
                    color: $hover;
                    &::before{
                        background: url(../images/6_pen_hover.png) no-repeat center center / contain;
                    }
                    &::after{
                        width: 100%;
                    }
                }
            }
            &::before{
                position: absolute;
                content: '';
                right: -40px;
                bottom: -2px;
                width: 42px;
                height: 54px;
                background: url(../images/6_pen.png) no-repeat center center / contain;
                transition: .4s ease;
            }
            &::after{
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 0;
                height: 3px;
                background-color: $hover;
                transition: .4s ease;
            }
        }
    }
}