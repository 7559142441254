.contactPage{
    .item1{
        position: relative;
        margin-top: 120px;
        margin-bottom: 160px;
        &::before{
            position: absolute;
            content: '';
            background: url(../images/bg1.png);
            top: 24%;
            left: -130px;
            width: 970px;
            height: 85%;
            @media (max-width:991px) {
                display: none;
            }
        }
        @media (max-width:991px) {
            margin-top: 80px;
            margin-bottom: 110px;
        }
        .container{
            position: relative;
        }
        .bg1{
            position: absolute;
            right: 0;
            top: 0;
            width: calc((100% - 1232px) / 2);
            height: 100%;
            background-color: #fff;
            @media (max-width:1199px){
                width: calc((100% - 896px) / 2);
            }
            @media (max-width:991px) {
                width: 50%;
            }
            &::before{
                position: absolute;
                content: '';
                right: 0;
                bottom: 0;
                height: 50%;
                width: 50%;
                background: url(../images/bg1.png);
                @media (max-width:991px) {
                    display: none;
                }
            }
        }
    }
    .it1-obx{
        background-color: #fff;
     
        padding: 0 16px;
        @media (max-width:991px) {
            padding: 0 24px;
        }
    }
    .it1-bx{
        max-width: 1022px;
        margin: 0 auto;
        width: 100%;
        padding-top: 105px;
        padding-bottom: 70px;
        @media (max-width:991px) {
            padding-top: 65px;
            padding-bottom: 40px;
        }
        .t-bx{
            display: flex;
            justify-content: space-between;
            @media (max-width:991px) {
                display: block;
            }
            .l{
                max-width: 403px;
                width: 95%;
                padding-right: 15px;
                @media (max-width:991px) {
                    width: 100%;
                    margin: 0 auto;
                }
                .ti{
                    @include fontset(40,1.45,4,400,#000);
                    margin-bottom: 22px;
                    @media (max-width:991px) {
                        font-size: pxToEm(35);
                        letter-spacing: .5px;
                        margin-bottom: 7px;
                    }
                }
                .des{
                    @include fontset(16,1.7,.8,300,#000);
                }
                ul{
                    margin-top: 45px;
                    @media (max-width:991px) {
                        margin-top: 25px;
                    }
                    li{
                        padding-left: 47px;
                        position: relative;
                        &:not(:last-child){
                            margin-bottom: 17px;   
                        }
                        .img-bx{
                            width: 32px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        .des,a{
                            @include fontset(16,1.4,.8,300,#000);
                        }
                    }
                }
            }
            .r{
                max-width: 495px;
                width: 95%;
                margin: 0 auto;
                @media (max-width:991px) {
                    width: 100%;
                    margin-top: 45px;
                }
                .form-bx{
                    label{
                        @include fontset(16,1.4,.8,500,#000);
                        display: block;
                        position: relative;
                        span{
                            color: $hover;
                            position: absolute;
                            left: -8px;
                            top: -3px;
                        }
                    }
                    input{
                        height: 32px;
                        border-radius: 0;
                        border: none;
                        border-bottom: 1px solid #a0a0a0;
                        transition: .4s ease;
                        position: relative;
                        &:focus{
                            border-color: $hover;
                        }
                    }
                    .help-block{
                        position: absolute;
                        margin-top: 3px;
                        height: 22px;
                        @include fontset(14,1.4,.7,300,#c40000);
                    }
                    .form-group{
                        margin-bottom: 28px;
                        position: relative;
                        @media (max-width:991px) {
                            margin-bottom: 25px;
                        }
                    }
                    .form-textbx{
                        .textarea-wrapper.textfocu{
                            border-color: $hover;
                        }
                        .textarea-wrapper{
                            height: 80px;
                            overflow: hidden;
                            border:none;
                            border-bottom: 1px solid #a0a0a0;
                            padding:0px 0px 10px;
                            margin-top: 0px;
                            background-color: #fff;
                            transition: .4s ease;
                            @media (max-width:991px) {
                                margin-top: 0;
                            }
                            @media (max-width:767px) {
                                height: 115px;
                            }
                            
                            textarea{
                                min-height: 66px;
                                height: 0;
                                overflow: hidden;
                                border-bottom:none;
                                padding-top:14px;
                                padding-left: 0px;
                                text-rendering: auto;
                                -webkit-writing-mode: horizontal-tb !important;
                                background-color: #fff;
                                resize: none;
                                border: none;
                                font-family: $font_content;
                                cursor:text;
                                @media (max-width:767px) {
                                    min-height: 99px;
                                    padding-top: 8px;
                                }
                                // &:focus{
                                //     border-color: $hover;
                                // }
                            }
                        }
                        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
                            background-color: $hover;
                            width: 3px;
                            left: -2px;
                            position: relative;
                            @media (max-width:991px) {
                                width: 3px;
                            }
                        }
                        .mCSB_scrollTools .mCSB_draggerRail{
                            background-color: #b4b4b4;
                            width: 2px;
                            @media (max-width:991px) {
                                width: 1px;
                            }
                        }
                        .mCSB_scrollTools{
                            right: -5px;
                        }
                        .mCSB_inside>.mCSB_container{
                            margin-right: 13px;
                        }
                        .mCSB_scrollTools .mCSB_draggerContainer{
                            top: 15px;
                            left: -3px;
                            @media (max-width:991px) {
                                top: 6px;
                            }
                        }
                    }
                    .send-btn{
                        width: 185px;
                        height: 61px;
                        border: solid 1px #a0a0a0;
                        text-align: center;
                        display: block;
                        @include fontset(16,3.9,.8,300,#000);
                        position: relative;
                        margin-top: 8px;
                        @media (max-width:991px) {
                            width: 120px;
                            height: 40px;
                            line-height: 2.6;
                            margin: 0 auto;
                            margin-top: 22px;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: 0;
                            left: 0;
                            width: 0;
                            height: 4px;
                            background-color: $hover;
                            transition: .4s ease;
                        }
                        @media (min-width:768px) {
                            &:hover{
                                &::before{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .b-bx{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width:991px) {
                display: block;
                margin-top: 70px;
            }
            .img-bx{
                max-width: 490px;
                width: 96%;
                order: 1;
                position: relative;
                @media (max-width:1199px) {
                    max-width: 395px;
                }
                @media (max-width:991px) {
                    width: 100%;
                    transform: scale(1.3);
                    left: -10px;
                    margin-top: 50px;
                }
                img{
                    position: relative;
                    left: -62px;
                    @media (max-width:991px) {
                        left: auto;
                    }
                }
            }
            .des-bx{
                order: 2;
                max-width: 495px;
                width: 95%;
                position: relative;
                top: -45px;
                @media (max-width:991px) {
                    width: 100%;
                    top: auto;
                }
                @media (min-width:1200px) {
                    position: relative;
                    left: -62px;
                }
                .ti{
                    @include fontset(40,1.45,4,400,#000);
                    margin-bottom: 22px;
                    @media (max-width:991px) {
                        font-size: pxToEm(35);
                        letter-spacing: .5px;
                        margin-bottom: 7px;
                    }
                }
                .des{
                    @include fontset(16,1.7,.8,300,#000);
                    margin-bottom: 40px;
                }
                .join-btn{
                    display: block;
                    width: 185px;
                    height: 61px;
                    background-color: #1d2088;
                    text-align: center;
                    @include fontset(16,3.9,.8,300,#fff);
                    position: relative;
                    @media (max-width:991px) {
                        width: 120px;
                        height: 40px;
                        line-height: 2.6;
                        margin: 0 auto;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        bottom: 3px;
                        left: 0;
                        width: 0;
                        height: 4px;
                        background-color: #fff;
                        transition: .4s ease;
                    }
                    @media (min-width:768px) {
                        &:hover{
                            &::before{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}





