footer{
    z-index : -10;
    padding: 25px 0;
    // background-color: #ffffff;
    border-top: 1px solid #000;
    width: calc(100% - 160px);
    margin: 0 auto;
    // background-color: #fafafa;
    @media(max-width:1199px){
        width: 90%;
    }
    @media(max-width:500px){
        padding: 15px 0;
    }
    .f-obx{
        max-width: 1264px;
        padding: 0 16px;
        margin: 0 auto;
        @media(max-width:500px){
            padding: 0;
        }

    }
    .footer-bx{
        display: flex;
        justify-content: space-between;
        @media(max-width:500px){
            display: block;
        }

        .l{
            .f-logo{
                width: 158px;
                height: 72px;
                background: url(../images/logo.png) center center no-repeat;
                background-size: contain;
                @media(max-width:500px){
                    width: 95px;
                    height: 43px;
                    margin-bottom: 30px;
                }
            }
        }
        .r{
            ul{
                width: 220px;
                @media(max-width:500px){
                    margin-bottom: 40px;
                }
                li{
                    a,.ti{
                        @include fontset(16,1.88,.8,400,#000);
                        @media(max-width:500px){
                            line-height: 1.45;
                        }
                        span{
                            position: relative;
                            margin-left: 11px;
                            padding-left: 10px;
                            &::before{
                                position: absolute;
                                content: '';
                                left: 0;
                                top: 1px;
                                height: 16px;
                                width: 1px;
                                background-color: #000;
                            }
                        }
                    }
                }
            }
        }
    }
    .copyright{
        @include fontset(16,1.3,.8,400,rgba(#000,.5));
        @media(max-width:500px){
            font-size: pxToEm(13);
        }
        i{
            @media (min-width:500px) {
                display: inline-block;
            }
        }
        a{
            @include fontset(16,1.3,.8,400,rgba(#000,.5));
            @media(max-width:500px){
                font-size: pxToEm(13);
                display: block;
            }
        }
    }
}

.fvider-morebx{
    a{
        display: block;
        width: 100%;
        height: 201px;
        background-color: #e6e6e6;
        @media (max-width:767px) {
            height: 140px;
        }
        .card{
            display: flex;
            align-items:center;
            max-width: 490px;
            width: 100%;
            margin: 0 auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width:767px) {
                justify-content: center;
            }
            .img-bx{
                width: 294px;
                @media (max-width:767px) {
                    width: 206px;
                }
            }
            .des-bx{
                padding-left: 70px;
                position: relative;
                @media (max-width:767px) {
                    padding-left: 15px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    bottom: -3px;
                    left: -10px;
                    width: calc(100% + 10px);
                    height: 1px;
                    background-color: #000;
                    @media (max-width:767px) {
                        left: -7px;
                        width: calc(100% + 7px);
                    }
                }
                .t{
                    @include fontset(24,1.5,2.4,500,#000);
                    margin-bottom: 15px;
                    @media (max-width:767px) {
                        font-size: pxToEm(20);
                        margin-bottom: 5px;
                    }
                }
                .des{
                    @include fontset(16,1.75,.8,300,#000);   
                    padding-left: 27px;
                    position: relative;
                    margin-left: -10px;
                    @media (max-width:767px) {
                        font-size: pxToEm(13);
                        padding-left: 13px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        border-left: 10px solid #000;
                        border-top: 6px dashed transparent;
                        border-bottom: 6px dashed transparent;
                        @media (max-width:767px) {
                            border-left: 8px solid #000;
                            border-top: 4px dashed transparent;
                            border-bottom: 4px dashed transparent;
                        }
                    }
                }
            }
        }
    }
}

.fser-listbx{
    padding-top: 120px;
    padding-bottom: 150px;
    background-color: #fafafa;
    @media (max-width:767px) {
        padding-top: 50px;
        padding-bottom: 85px;
    }
    .sh-lti{
        margin-bottom: 55px;
        @media (max-width:767px) {
            margin-bottom: 25px;
        }
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 65px;
        position: relative;
        z-index: 5;
        @media (max-width:767px) {
            margin-bottom: 25px;
        }
        &::before{
            position: absolute;
            content: '';
            right: -170px;
            top: -260px;
            background: url(../images/bg2.png) no-repeat center center / contain;
            width: 600px;
            height: 600px;
            z-index: -1;
            @media (max-width:991px) {
                right: -145px;
                top: -115px;
                width: 350px;
                height: 350px;
            }
        }
        &::after{
            position: absolute;
            content: '';
            left: -220px;
            bottom: -350px;
            background: url(../images/bg2.png) no-repeat center center / contain;
            width: 700px;
            height: 700px;
            z-index: -1;
            @media (max-width:991px) {
                left: -145px;
                top: 70px;
                width: 350px;
                height: 350px;
            }
        }
        li{
            width: 46%;
            display: flex;
            align-items: flex-start;
            background-color: #fff;
            padding-top: 40px;
            padding-bottom: 38px;
            padding-left: 50px;
            padding-right: 10px;
            position: relative;
            margin-bottom: 8px;
            @media (max-width:1199px) {
                padding-left: 25px;
            }
            @media (max-width:991px) {
                width: 100%;
                padding-top: 18px;
                padding-bottom: 16px;
                display: block;
                margin-bottom: 12px;
            }
            &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                width: 5px;
                height: 100%;
                background-color: $hover;
                @media (max-width:991px) {
                    width: 3px;
                }
            }
            &:nth-child(odd){
                margin-right: 8%;
                @media (max-width:991px) {
                    margin-right: 0;
                }
            }
            .ti{
                min-width: 130px;
                width: 130px;
                margin-right: 28px;
                @include fontset(18,1.4,0,300,#000);
                @media (max-width:1199px) {
                    min-width: 100px;
                    width: 100px;
                }
                @media (max-width:991px) {
                    min-width: 100%;
                    width: 100%;
                    margin-bottom: 12px;
                }
            }
            .link-bx{
                a{
                    @include fontset(16,1.88,.8,400,#000);
                    position: relative;
                    padding-left: 20px;
                    display: block;
                    img{
                        position: absolute;
                        left: 0;
                        top: 7px;
                        width: 11px;
                    }
                }
            }
        }
    }
    .viewall{
        width: 65px;
        height: 60px;
        display: block;
        margin: 0 auto;
        text-align: center;
        @include fontset(16,1.88,.8,500,#000);
        .img-bx{
            width: 18px;
            margin: 0 auto;
            margin-top: -10px;
        }   
        @media (min-width:768px) {
            &:hover{
                color: $hover;
            }
        }
    }
}