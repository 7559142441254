@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
/* > reset */
/******************************************/
/*		slick
/******************************************/
/* Slider */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&family=Roboto+Condensed:wght@300;400;700&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/******************************************/
/*		fullpage
/******************************************/
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: #444; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Custom CSS
 * --------------------------------------- */
body {
  font-family: arial,helvetica;
  color: #333;
  color: rgba(0, 0, 0, 0.5); }

.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative; }

h1 {
  font-size: 6em; }

p {
  font-size: 2em; }

.intro p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.5em; }

.section {
  text-align: center; }

#menu li {
  display: inline-block;
  margin: 10px;
  color: #000;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

#menu li.active {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

#menu li a {
  text-decoration: none;
  color: #000; }

#menu li.active a:hover {
  color: #000; }

#menu li:hover {
  background: rgba(255, 255, 255, 0.8); }

#menu li a,
#menu li.active a {
  padding: 9px 18px;
  display: block; }

#menu li.active a {
  color: #fff; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0; }

.twitter-share-button {
  position: fixed;
  z-index: 99;
  right: 149px;
  top: 9px; }

#download {
  margin: 10px 0 0 0;
  padding: 15px 10px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#download a {
  text-decoration: none;
  color: #fff; }

#download:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2F96B4;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  -ms-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear; }

#infoMenu {
  height: 20px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding: 8px 0 8px 0; }

#infoMenu ul {
  padding: 0 40px; }

#infoMenu li a {
  display: block;
  margin: 0 22px 0 0;
  color: #333; }

#infoMenu li a:hover {
  text-decoration: underline; }

#infoMenu li {
  display: inline-block;
  position: relative; }

#examplesList {
  display: none;
  background: #282828;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 20px;
  float: left;
  position: absolute;
  bottom: 29px;
  right: 0;
  width: 638px;
  text-align: left; }

#examplesList ul {
  padding: 0; }

#examplesList ul li {
  display: block;
  margin: 5px 0; }

#examplesList ul li a {
  color: #BDBDBD;
  margin: 0; }

#examplesList ul li a:hover {
  color: #f2f2f2; }

#examplesList .column {
  float: left;
  margin: 0 20px 0 0; }

#examplesList h3 {
  color: #f2f2f2;
  font-size: 1.2em;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  padding: 0 0 5px 0; }

/* Demos Menu
 * --------------------------------------- */
#demosMenu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #1d2088; }

html, body {
  cursor: none; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  z-index: 0;
  -webkit-text-size-adjust: 100%;
  opacity: 0;
  transition: 1.2s;
  font-family: "Roboto Condensed" "Noto Sans TC";
  background-color: #fafafa; }

body.loading {
  opacity: 1;
  transition: opacity 1.2s; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #a0a0a0;
  font-size: 1rem;
  line-height: 18px; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a0a0a0;
  font-size: 1rem;
  line-height: 25px; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a0a0a0;
  font-size: 1rem;
  line-height: 25px; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #a0a0a0;
  font-size: 1rem;
  line-height: 18px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC";
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 106px; }
  @media (max-width: 991px) {
    main[role="main"] {
      margin-top: 70px; } }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  font-size: 1rem;
  color: #000000;
  line-height: 1.75;
  letter-spacing: 0.8px; }
  .editor_Content ol {
    list-style-type: decimal;
    padding-left: 10px; }
  .editor_Content ul {
    list-style-type: initial;
    padding-left: 20px; }
  .editor_Content img {
    max-width: 100%;
    height: auto !important; }
  .editor_Content b, .editor_Content strong {
    font-weight: bold;
    font-size: inherit; }
  .editor_Content em {
    font-style: italic; }
  .editor_Content a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_Content iframe {
    max-width: 100%; }
  .editor_Content h1, .editor_Content h2, .editor_Content h3, .editor_Content h4, .editor_Content h5, .editor_Content h6 {
    margin: inherit; }
  .editor_Content blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    margin-left: 40px;
    border-left: 5px solid #ccc; }
  .editor_Content td, .editor_Content th, .editor_Content tr {
    margin: initial; }
  .editor_Content table {
    border-collapse: inherit; }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.9375rem;
  color: #000000; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: 100% !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .seesaw a {
    color: #de4242;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #de4242;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #de4242;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #de4242; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.top_btn.show-topbtn {
  opacity: 1; }

.top_btn {
  cursor: pointer;
  transition: .4s;
  opacity: 0;
  position: fixed;
  bottom: 20px;
  right: 80px;
  z-index: 8;
  font-size: 1rem;
  color: #000;
  letter-spacing: 0;
  line-height: 1.8;
  z-index: 9;
  width: 51px;
  height: 100px; }
  @media (max-width: 767px) {
    .top_btn {
      font-size: 1.0625rem; } }
  .top_btn .img-bx {
    height: 73px;
    width: 100%;
    position: relative; }
    .top_btn .img-bx .leaf {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: .4s ease; }
    .top_btn .img-bx .root {
      position: absolute;
      bottom: 0;
      right: 15px; }
  @media (min-width: 768px) {
    .top_btn:hover .img-bx .leaf {
      transform: translateX(-50%) rotate(90deg); } }
  @media (max-width: 1199px) {
    .top_btn {
      right: 40px; } }
  .top_btn.fix {
    position: absolute; }

/*----------------------頁數------------------------*/
.page {
  font-size: 1rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 130px; }
  @media (max-width: 767px) {
    .page {
      margin-top: 65px; } }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block; }
    .page dt a,
    .page dd a {
      display: block;
      height: 30px;
      line-height: 30px;
      text-decoration: none;
      color: #959595;
      transition: .4s; }
      @media (max-width: 767px) {
        .page dt a,
        .page dd a {
          line-height: 26px; } }
  .page dd {
    width: 26px;
    height: 31px;
    margin: 0 7px;
    position: relative;
    transition: .4s;
    border-radius: 5px; }
    @media (max-width: 767px) {
      .page dd {
        width: 24px;
        height: 26px;
        margin: 0 3px; } }
    @media (max-width: 350px) {
      .page dd {
        margin: 0px; } }
    .page dd::before {
      position: absolute;
      content: '';
      width: 55%;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      transition: .4s;
      opacity: 0;
      pointer-events: none;
      border-bottom: 1px solid #1d2088; }
    .page dd span {
      position: relative; }
    @media (min-width: 767px) {
      .page dd:hover::before {
        opacity: 1; }
      .page dd:hover a {
        color: #1d2088; } }
  .page dd.active::before {
    opacity: 1; }
  .page dd.active a {
    color: #1d2088; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: 32px;
    height: 32px;
    transition: 0.4s; }
  .page dt.ltbn.nopage a::before, .page dt.ltbn.nopage a::after, .page dt.rtbn.nopage a::before, .page dt.rtbn.nopage a::after {
    background-color: #959595; }
  @media (min-width: 767px) {
    .page dt.ltbn.nopage a:hover::before, .page dt.ltbn.nopage a:hover::after, .page dt.rtbn.nopage a:hover::before, .page dt.rtbn.nopage a:hover::after {
      background-color: #959595; } }
  .page dt.ltbn a {
    position: relative; }
    @media (max-width: 767px) {
      .page dt.ltbn a {
        margin-right: 0; } }
    .page dt.ltbn a:before {
      background-color: #313131;
      position: absolute;
      content: '';
      top: 10px;
      left: 50%;
      transform: translateX(-50%) rotate(50deg);
      width: 1px;
      height: 8px;
      transition: .4s; }
    .page dt.ltbn a::after {
      background-color: #313131;
      position: absolute;
      content: '';
      top: 15px;
      left: 50%;
      transform: translateX(-50%) rotate(-50deg);
      width: 1px;
      height: 8px;
      transition: .4s; }
    @media (min-width: 767px) {
      .page dt.ltbn a:hover::before, .page dt.ltbn a:hover::after {
        background-color: #313131; } }
  .page dt.rtbn a {
    position: relative; }
    @media (max-width: 767px) {
      .page dt.rtbn a {
        margin-left: 0; } }
    .page dt.rtbn a:before {
      background-color: #313131;
      position: absolute;
      content: '';
      top: 10px;
      left: 50%;
      transform: translateX(-50%) rotate(-50deg);
      width: 1px;
      height: 8px;
      transition: .4s; }
    .page dt.rtbn a::after {
      background-color: #313131;
      position: absolute;
      content: '';
      top: 15px;
      left: 50%;
      transform: translateX(-50%) rotate(50deg);
      width: 1px;
      height: 8px;
      transition: .4s; }
    @media (min-width: 767px) {
      .page dt.rtbn a:hover::before, .page dt.rtbn a:hover::after {
        background-color: #313131; } }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 20px;
    transform: scale(0.8);
    bottom: 0; }
  right: 20px; }

@media (max-width: 767px) {
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

/*----------------------header------------------------*/
.header_show {
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(19, 19, 20, 0);
  z-index: 10;
  background-color: #fff; }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.header_box {
  position: relative;
  width: 100%;
  height: 106px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  max-width: 1264px;
  margin: 0 auto; }

.logo {
  width: 158px;
  height: 72px;
  background: url(../images/logo.png) center center no-repeat;
  background-size: contain;
  text-indent: -10000px; }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }

.member_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 125px;
  z-index: 1;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.8px;
  font-weight: 700;
  color: #000; }
  @media (max-width: 767px) {
    .member_btn {
      font-size: 0.75rem;
      line-height: 1.2;
      right: 80px;
      top: 56%;
      z-index: 1; } }
  .member_btn img {
    width: 24px;
    margin-right: 3px;
    position: relative;
    top: -3px; }
    @media (max-width: 767px) {
      .member_btn img {
        width: 18px; } }

/*----------------------nav------------------------*/
.nav_box {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  z-index: 8; }
  .nav_box::before {
    position: absolute;
    content: '';
    bottom: 30px;
    left: 0;
    width: 100%;
    height: 447px;
    background: url(../images/menu_pc.png) no-repeat center center/contain; }
    @media (max-width: 767px) {
      .nav_box::before {
        display: none; } }
  .nav_box nav {
    max-width: 1264px;
    width: 100%;
    position: absolute;
    top: 43%;
    left: 55%;
    transform: translate(-50%, -50%);
    padding: 0 16px; }
    @media (max-width: 1200px) {
      .nav_box nav {
        top: 50%; } }
  .nav_box ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap; }
    .nav_box ul li {
      position: relative;
      width: 33.33333%; }
      .nav_box ul li > a {
        font-size: 1.5rem;
        line-height: 0.9;
        letter-spacing: 1px;
        font-weight: 400;
        color: #000;
        position: relative;
        padding-left: 15px;
        border-left: 5px solid #000;
        text-align: left; }
        @media (min-width: 768px) {
          .nav_box ul li > a:hover {
            color: #1d2088;
            border-color: #1d2088; } }
      .nav_box ul li .under-bx {
        padding-left: 15px;
        margin-top: 20px;
        margin-bottom: 55px; }
        .nav_box ul li .under-bx a {
          font-size: 1rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          font-weight: 300;
          color: #000; }
          @media (min-width: 768px) {
            .nav_box ul li .under-bx a:hover {
              color: #1d2088; } }
          .nav_box ul li .under-bx a:not(:last-child) {
            margin-bottom: 10px; }
  .nav_box a {
    width: 100%;
    display: block; }
    .nav_box a:after {
      transition: .4s;
      position: relative;
      content: '';
      width: 0;
      height: 2px;
      background: #4759d9;
      bottom: -26px;
      left: 8px;
      opacity: 0; }
    .nav_box a:hover:after {
      width: 77%;
      opacity: 1; }
    .nav_box a:hover .en {
      opacity: 0; }
    .nav_box a:hover .tw {
      opacity: 1; }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: block; }

.menu-wrapper {
  display: block;
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto;
  width: 31px;
  height: 23px;
  cursor: pointer;
  z-index: 10; }

.hamburger-menu {
  width: 12px; }

.hamburger-menu:after {
  width: 24px; }

.hamburger-menu:before {
  width: 30px; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  height: 3px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em; }
  @media (max-width: 767px) {
    .hamburger-menu,
    .hamburger-menu:after,
    .hamburger-menu:before {
      height: 2px; } }

.hamburger-menu {
  position: relative;
  transform: translateY(10px);
  background: #000;
  transition: all 0ms 100ms;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0);
    transition: all 0ms 0ms; }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: #000;
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: #000;
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:after {
  top: 0;
  width: 30px;
  transform: rotate(135deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(225deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 991px) {
  .logo {
    width: 95px;
    height: 43px; }
  .header_box {
    width: 100%;
    height: 70px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0 24px; }
  .nav_box nav {
    width: 100%;
    margin: auto;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin-top: 9vw;
    height: calc(100% + 40px); }
    .nav_box nav::before {
      position: absolute;
      content: '';
      bottom: 156px;
      left: 0;
      width: 100%;
      background: url(../images/menu_mb.png) no-repeat center center/contain;
      height: 169px; }
  .nav_box {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100% + 100px);
    z-index: 11;
    overflow-y: scroll; }
    .nav_box ul {
      display: block; }
      .nav_box ul li {
        width: 100%; }
        .nav_box ul li a {
          padding: 20px 15px;
          text-align: center;
          text-decoration: none;
          border-left: none;
          font-size: 1.25rem;
          line-height: .8; }
          .nav_box ul li a span {
            padding-left: 15px;
            position: relative; }
            .nav_box ul li a span::before {
              position: absolute;
              content: '';
              top: 2px;
              left: 0;
              height: 18px;
              background-color: #000;
              width: 2px; }
        .nav_box ul li .under-bx {
          max-width: 100px;
          margin: 0 auto;
          text-align: left;
          margin-top: 5px;
          margin-bottom: 12px; }
          .nav_box ul li .under-bx a {
            padding: 5px 0;
            text-align: left; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .menu-wrapper {
    display: block; } }

footer {
  z-index: -10;
  padding: 25px 0;
  border-top: 1px solid #000;
  width: calc(100% - 160px);
  margin: 0 auto; }
  @media (max-width: 1199px) {
    footer {
      width: 90%; } }
  @media (max-width: 500px) {
    footer {
      padding: 15px 0; } }
  footer .f-obx {
    max-width: 1264px;
    padding: 0 16px;
    margin: 0 auto; }
    @media (max-width: 500px) {
      footer .f-obx {
        padding: 0; } }
  footer .footer-bx {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 500px) {
      footer .footer-bx {
        display: block; } }
    footer .footer-bx .l .f-logo {
      width: 158px;
      height: 72px;
      background: url(../images/logo.png) center center no-repeat;
      background-size: contain; }
      @media (max-width: 500px) {
        footer .footer-bx .l .f-logo {
          width: 95px;
          height: 43px;
          margin-bottom: 30px; } }
    footer .footer-bx .r ul {
      width: 220px; }
      @media (max-width: 500px) {
        footer .footer-bx .r ul {
          margin-bottom: 40px; } }
      footer .footer-bx .r ul li a, footer .footer-bx .r ul li .ti {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 0.8px;
        font-weight: 400;
        color: #000; }
        @media (max-width: 500px) {
          footer .footer-bx .r ul li a, footer .footer-bx .r ul li .ti {
            line-height: 1.45; } }
        footer .footer-bx .r ul li a span, footer .footer-bx .r ul li .ti span {
          position: relative;
          margin-left: 11px;
          padding-left: 10px; }
          footer .footer-bx .r ul li a span::before, footer .footer-bx .r ul li .ti span::before {
            position: absolute;
            content: '';
            left: 0;
            top: 1px;
            height: 16px;
            width: 1px;
            background-color: #000; }
  footer .copyright {
    font-size: 1rem;
    line-height: 1.3;
    letter-spacing: 0.8px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5); }
    @media (max-width: 500px) {
      footer .copyright {
        font-size: 0.8125rem; } }
    @media (min-width: 500px) {
      footer .copyright i {
        display: inline-block; } }
    footer .copyright a {
      font-size: 1rem;
      line-height: 1.3;
      letter-spacing: 0.8px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5); }
      @media (max-width: 500px) {
        footer .copyright a {
          font-size: 0.8125rem;
          display: block; } }

.fvider-morebx a {
  display: block;
  width: 100%;
  height: 201px;
  background-color: #e6e6e6; }
  @media (max-width: 767px) {
    .fvider-morebx a {
      height: 140px; } }
  .fvider-morebx a .card {
    display: flex;
    align-items: center;
    max-width: 490px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .fvider-morebx a .card {
        justify-content: center; } }
    .fvider-morebx a .card .img-bx {
      width: 294px; }
      @media (max-width: 767px) {
        .fvider-morebx a .card .img-bx {
          width: 206px; } }
    .fvider-morebx a .card .des-bx {
      padding-left: 70px;
      position: relative; }
      @media (max-width: 767px) {
        .fvider-morebx a .card .des-bx {
          padding-left: 15px; } }
      .fvider-morebx a .card .des-bx::before {
        position: absolute;
        content: '';
        bottom: -3px;
        left: -10px;
        width: calc(100% + 10px);
        height: 1px;
        background-color: #000; }
        @media (max-width: 767px) {
          .fvider-morebx a .card .des-bx::before {
            left: -7px;
            width: calc(100% + 7px); } }
      .fvider-morebx a .card .des-bx .t {
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: 2.4px;
        font-weight: 500;
        color: #000;
        margin-bottom: 15px; }
        @media (max-width: 767px) {
          .fvider-morebx a .card .des-bx .t {
            font-size: 1.25rem;
            margin-bottom: 5px; } }
      .fvider-morebx a .card .des-bx .des {
        font-size: 1rem;
        line-height: 1.75;
        letter-spacing: 0.8px;
        font-weight: 300;
        color: #000;
        padding-left: 27px;
        position: relative;
        margin-left: -10px; }
        @media (max-width: 767px) {
          .fvider-morebx a .card .des-bx .des {
            font-size: 0.8125rem;
            padding-left: 13px; } }
        .fvider-morebx a .card .des-bx .des::before {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          border-left: 10px solid #000;
          border-top: 6px dashed transparent;
          border-bottom: 6px dashed transparent; }
          @media (max-width: 767px) {
            .fvider-morebx a .card .des-bx .des::before {
              border-left: 8px solid #000;
              border-top: 4px dashed transparent;
              border-bottom: 4px dashed transparent; } }

.fser-listbx {
  padding-top: 120px;
  padding-bottom: 150px;
  background-color: #fafafa; }
  @media (max-width: 767px) {
    .fser-listbx {
      padding-top: 50px;
      padding-bottom: 85px; } }
  .fser-listbx .sh-lti {
    margin-bottom: 55px; }
    @media (max-width: 767px) {
      .fser-listbx .sh-lti {
        margin-bottom: 25px; } }
  .fser-listbx ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 65px;
    position: relative;
    z-index: 5; }
    @media (max-width: 767px) {
      .fser-listbx ul {
        margin-bottom: 25px; } }
    .fser-listbx ul::before {
      position: absolute;
      content: '';
      right: -170px;
      top: -260px;
      background: url(../images/bg2.png) no-repeat center center/contain;
      width: 600px;
      height: 600px;
      z-index: -1; }
      @media (max-width: 991px) {
        .fser-listbx ul::before {
          right: -145px;
          top: -115px;
          width: 350px;
          height: 350px; } }
    .fser-listbx ul::after {
      position: absolute;
      content: '';
      left: -220px;
      bottom: -350px;
      background: url(../images/bg2.png) no-repeat center center/contain;
      width: 700px;
      height: 700px;
      z-index: -1; }
      @media (max-width: 991px) {
        .fser-listbx ul::after {
          left: -145px;
          top: 70px;
          width: 350px;
          height: 350px; } }
    .fser-listbx ul li {
      width: 46%;
      display: flex;
      align-items: flex-start;
      background-color: #fff;
      padding-top: 40px;
      padding-bottom: 38px;
      padding-left: 50px;
      padding-right: 10px;
      position: relative;
      margin-bottom: 8px; }
      @media (max-width: 1199px) {
        .fser-listbx ul li {
          padding-left: 25px; } }
      @media (max-width: 991px) {
        .fser-listbx ul li {
          width: 100%;
          padding-top: 18px;
          padding-bottom: 16px;
          display: block;
          margin-bottom: 12px; } }
      .fser-listbx ul li::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 5px;
        height: 100%;
        background-color: #1d2088; }
        @media (max-width: 991px) {
          .fser-listbx ul li::before {
            width: 3px; } }
      .fser-listbx ul li:nth-child(odd) {
        margin-right: 8%; }
        @media (max-width: 991px) {
          .fser-listbx ul li:nth-child(odd) {
            margin-right: 0; } }
      .fser-listbx ul li .ti {
        min-width: 130px;
        width: 130px;
        margin-right: 28px;
        font-size: 1.125rem;
        line-height: 1.4;
        letter-spacing: 0px;
        font-weight: 300;
        color: #000; }
        @media (max-width: 1199px) {
          .fser-listbx ul li .ti {
            min-width: 100px;
            width: 100px; } }
        @media (max-width: 991px) {
          .fser-listbx ul li .ti {
            min-width: 100%;
            width: 100%;
            margin-bottom: 12px; } }
      .fser-listbx ul li .link-bx a {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 0.8px;
        font-weight: 400;
        color: #000;
        position: relative;
        padding-left: 20px;
        display: block; }
        .fser-listbx ul li .link-bx a img {
          position: absolute;
          left: 0;
          top: 7px;
          width: 11px; }
  .fser-listbx .viewall {
    width: 65px;
    height: 60px;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.8px;
    font-weight: 500;
    color: #000; }
    .fser-listbx .viewall .img-bx {
      width: 18px;
      margin: 0 auto;
      margin-top: -10px; }
    @media (min-width: 768px) {
      .fser-listbx .viewall:hover {
        color: #1d2088; } }

/******************************************/
/*		pages
/******************************************/
.indexPage main {
  margin-top: 0; }
  @media (max-width: 991px) {
    .indexPage main {
      margin-top: 70px; } }

.indexPage .banner {
  position: relative; }
  @media (max-width: 991px) {
    .indexPage .banner {
      transform: scale(1.1); } }
  .indexPage .banner .in-b {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .indexPage .banner img {
    width: 100%; }

.indexPage .item1 {
  background-color: #f2f2f2;
  padding: 120px 0; }
  @media (max-width: 991px) {
    .indexPage .item1 {
      padding: 60px 0;
      padding-bottom: 45px; } }
  .indexPage .item1 .ti-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    @media (max-width: 991px) {
      .indexPage .item1 .ti-flex {
        display: block; } }
    .indexPage .item1 .ti-flex .sh-des {
      text-align: right; }
      @media (max-width: 991px) {
        .indexPage .item1 .ti-flex .sh-des {
          text-align: left;
          margin-top: 30px; } }
  .indexPage .item1 .it1-list {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 991px) {
      .indexPage .item1 .it1-list {
        margin-top: 30px; } }
    @media (min-width: 992px) {
      .indexPage .item1 .it1-list li {
        width: calc((100% - 48px) / 4); }
        .indexPage .item1 .it1-list li:not(:last-child) {
          margin-right: 16px; } }
    @media (max-width: 991px) {
      .indexPage .item1 .it1-list li {
        width: calc((100% - 10px) / 2);
        margin-bottom: 30px; }
        .indexPage .item1 .it1-list li:not(:nth-child(even)) {
          margin-right: 10px; } }
    .indexPage .item1 .it1-list li a {
      display: block; }
      @media (min-width: 768px) {
        .indexPage .item1 .it1-list li a:hover .ti {
          color: #1d2088; }
        .indexPage .item1 .it1-list li a:hover .img-bx img {
          transform: scale(1.2); } }
      .indexPage .item1 .it1-list li a .img-bx {
        position: relative;
        overflow: hidden; }
        .indexPage .item1 .it1-list li a .img-bx img {
          transition: .4s ease;
          width: 100%; }
      .indexPage .item1 .it1-list li a .ti {
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.25;
        letter-spacing: 2.4px;
        font-weight: 500;
        color: #000;
        padding: 0 15px;
        padding-top: 45px;
        position: relative; }
        @media (max-width: 991px) {
          .indexPage .item1 .it1-list li a .ti {
            padding-top: 25px;
            font-size: 1.25rem; } }
        .indexPage .item1 .it1-list li a .ti::before {
          position: absolute;
          content: '';
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 1px;
          height: 35px;
          background-color: #000; }
          @media (max-width: 991px) {
            .indexPage .item1 .it1-list li a .ti::before {
              height: 20px; } }

.indexPage .item2 {
  background-color: #fafafa;
  padding: 120px 0;
  padding-bottom: 170px;
  position: relative; }
  @media (max-width: 991px) {
    .indexPage .item2 {
      padding-top: 75px;
      padding-bottom: 65px; } }
  .indexPage .item2 .container {
    position: relative;
    z-index: 4; }
  .indexPage .item2 .bg-l {
    position: absolute;
    right: 0;
    top: 0;
    width: 75.5%;
    height: 100%;
    background: url(../images/bg1.png);
    z-index: 2; }
    @media (max-width: 991px) {
      .indexPage .item2 .bg-l {
        background-size: contain;
        height: 60%; } }
  .indexPage .item2 .bg-r {
    position: absolute;
    left: 0;
    top: 0;
    width: 47%;
    height: calc(100% - 50px);
    background-color: #fff;
    z-index: 3; }
    @media (max-width: 991px) {
      .indexPage .item2 .bg-r {
        height: 45%;
        width: 41%; } }
  .indexPage .item2 .ti-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    @media (max-width: 991px) {
      .indexPage .item2 .ti-flex {
        display: block; } }
    .indexPage .item2 .ti-flex .sh-des {
      text-align: right; }
      @media (max-width: 991px) {
        .indexPage .item2 .ti-flex .sh-des {
          text-align: left;
          margin-top: 25px; } }
  .indexPage .item2 .it2-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px; }
    @media (max-width: 991px) {
      .indexPage .item2 .it2-list {
        margin-top: 15px; } }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-list li.moshow a:before {
        opacity: .1; } }
    .indexPage .item2 .it2-list li {
      position: relative; }
      @media (min-width: 992px) {
        .indexPage .item2 .it2-list li {
          width: calc((100% - 30px) / 3);
          margin-bottom: 15px; }
          .indexPage .item2 .it2-list li:not(:nth-child(3n)) {
            margin-right: 15px; } }
      @media (max-width: 991px) {
        .indexPage .item2 .it2-list li {
          margin-bottom: 12px; } }
      @media (min-width: 767px) {
        .indexPage .item2 .it2-list li:hover .img-bx img {
          transform: scale(1.2); }
        .indexPage .item2 .it2-list li:hover a:before {
          opacity: 0; } }
      .indexPage .item2 .it2-list li .img-bx {
        position: relative;
        overflow: hidden; }
        .indexPage .item2 .it2-list li .img-bx img {
          transition: .4s ease; }
      .indexPage .item2 .it2-list li span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
        line-height: 1.25;
        letter-spacing: 2.4px;
        font-weight: 500;
        color: #FFF;
        padding: 0 15px;
        text-align: center;
        z-index: 5;
        text-shadow: 1px 1px 4px black; }
        @media (max-width: 991px) {
          .indexPage .item2 .it2-list li span {
            font-size: 1.25rem; } }
      .indexPage .item2 .it2-list li a {
        display: block;
        position: relative; }
        .indexPage .item2 .it2-list li a::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: .5;
          transition: .4s ease;
          z-index: 3; }

.indexPage .item3 {
  padding-top: 60px;
  padding-bottom: 110px;
  background-color: #fafafa; }
  @media (max-width: 991px) {
    .indexPage .item3 {
      padding-top: 0;
      padding-bottom: 75px; } }
  .indexPage .item3 .container {
    position: relative; }
  .indexPage .item3 .bgball {
    background: url(../images/bg2.png) no-repeat center center/contain;
    position: absolute;
    z-index: 0;
    pointer-events: none; }
  .indexPage .item3 .bg1 {
    top: 0;
    left: -628px;
    width: 530px;
    height: 480px; }
    @media (max-width: 991px) {
      .indexPage .item3 .bg1 {
        display: none; } }
  .indexPage .item3 .bg2 {
    width: 767px;
    height: 600px;
    top: -30px;
    right: -218px; }
    @media (max-width: 991px) {
      .indexPage .item3 .bg2 {
        width: 380px;
        height: 292px;
        top: 14px;
        right: 55px; } }
  .indexPage .item3 .bg3 {
    width: 800px;
    height: 639px;
    top: 414px;
    left: -237px; }
    @media (max-width: 991px) {
      .indexPage .item3 .bg3 {
        left: auto;
        width: 300px;
        height: 259px;
        top: 394px;
        right: -124px; } }
  .indexPage .item3 .bg4 {
    width: 530px;
    height: 486px;
    bottom: -185px;
    right: -490px; }
    @media (max-width: 991px) {
      .indexPage .item3 .bg4 {
        display: none; } }
  .indexPage .item3 .ti-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    .indexPage .item3 .ti-flex .sh-allbtn {
      text-align: center;
      display: block;
      width: 148px;
      height: 60px;
      font-size: 1rem;
      line-height: 3.7;
      letter-spacing: 0.8px;
      font-weight: 400;
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.3);
      transition: .4s ease;
      position: relative; }
      @media (max-width: 991px) {
        .indexPage .item3 .ti-flex .sh-allbtn {
          width: 95px;
          height: 40px;
          line-height: 2.5; } }
      .indexPage .item3 .ti-flex .sh-allbtn::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 4px;
        background-color: #1d2088;
        content: '';
        transition: .5s ease; }
      @media (min-width: 768px) {
        .indexPage .item3 .ti-flex .sh-allbtn:hover {
          border: 1px solid rgba(0, 0, 0, 0.7);
          color: #1d2088; }
          .indexPage .item3 .ti-flex .sh-allbtn:hover::before {
            width: 100%; } }
  .indexPage .item3 .news-list {
    margin-top: 100px;
    position: relative;
    z-index: 5; }
    @media (max-width: 991px) {
      .indexPage .item3 .news-list {
        margin-top: 35px; } }

.indexPage .item4 {
  background-color: #fafafa;
  padding-bottom: 115px; }
  @media (max-width: 991px) {
    .indexPage .item4 {
      padding-bottom: 95px; } }
  .indexPage .item4 .it4-bx {
    display: flex;
    justify-content: space-between;
    margin-top: 55px; }
    @media (max-width: 1199px) {
      .indexPage .item4 .it4-bx {
        margin-top: 30px; } }
    @media (max-width: 991px) {
      .indexPage .item4 .it4-bx {
        display: block; } }
    .indexPage .item4 .it4-bx .l {
      width: 50%;
      margin-top: 27px; }
      @media (max-width: 991px) {
        .indexPage .item4 .it4-bx .l {
          width: 100%; } }
    .indexPage .item4 .it4-bx .r {
      width: 49%; }
      @media (max-width: 991px) {
        .indexPage .item4 .it4-bx .r {
          width: 100%;
          margin-top: 70px; } }
  .indexPage .item4 .con-btn {
    display: inline-block;
    padding: 7px 0;
    padding-right: 10px;
    position: relative;
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.8px;
    font-weight: 400;
    color: #000;
    border-bottom: 1px solid #000;
    transition: .4s ease;
    margin-top: 75px; }
    @media (max-width: 991px) {
      .indexPage .item4 .con-btn {
        margin-top: 65px;
        top: 0; } }
    @media (min-width: 768px) {
      .indexPage .item4 .con-btn:hover {
        color: #1d2088; }
        .indexPage .item4 .con-btn:hover::before {
          background: url(../images/6_pen_hover.png) no-repeat center center/contain; }
        .indexPage .item4 .con-btn:hover::after {
          width: 100%; } }
    .indexPage .item4 .con-btn::before {
      position: absolute;
      content: '';
      right: -40px;
      bottom: -2px;
      width: 42px;
      height: 54px;
      background: url(../images/6_pen.png) no-repeat center center/contain;
      transition: .4s ease; }
    .indexPage .item4 .con-btn::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 0;
      height: 3px;
      background-color: #1d2088;
      transition: .4s ease; }

.aboutPage .item1 {
  margin-top: 180px;
  padding-bottom: 95px; }
  @media (max-width: 1199px) {
    .aboutPage .item1 {
      margin-top: 90px; } }
  @media (max-width: 767px) {
    .aboutPage .item1 {
      margin-top: 50px;
      padding-bottom: 0; } }
  .aboutPage .item1 .l-ti {
    font-size: 1.5rem;
    line-height: 1.5;
    letter-spacing: 2.4px;
    font-weight: 500;
    color: #000;
    text-align: center; }
    @media (max-width: 767px) {
      .aboutPage .item1 .l-ti {
        font-size: 1.25rem;
        text-align: left;
        margin-top: 8px;
        margin-bottom: 20px; } }
    .aboutPage .item1 .l-ti span {
      padding-left: 17px;
      position: relative; }
      @media (max-width: 767px) {
        .aboutPage .item1 .l-ti span {
          padding-left: 12px; } }
      .aboutPage .item1 .l-ti span::before {
        position: absolute;
        content: '';
        left: 0;
        top: 3px;
        width: 1px;
        height: 83%;
        background-color: #000; }
  .aboutPage .item1 .it1-bx {
    margin-top: 30px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3; }
    @media (max-width: 991px) {
      .aboutPage .item1 .it1-bx {
        display: block; } }
    .aboutPage .item1 .it1-bx .l {
      width: 40%; }
      @media (max-width: 991px) {
        .aboutPage .item1 .it1-bx .l {
          width: 100%; } }
      .aboutPage .item1 .it1-bx .l .bgcover {
        padding-bottom: 271px;
        background-size: cover !important; }
        @media (max-width: 767px) {
          .aboutPage .item1 .it1-bx .l .bgcover {
            padding-bottom: 55%; } }
    .aboutPage .item1 .it1-bx .r {
      width: 60%;
      padding-left: 3%;
      padding-top: 4.5%;
      position: relative;
      height: 271px; }
      @media (max-width: 991px) {
        .aboutPage .item1 .it1-bx .r {
          width: 100%;
          height: auto;
          padding-bottom: 40px;
          padding-left: 10px;
          padding-right: 10px; } }
      @media (max-width: 570px) {
        .aboutPage .item1 .it1-bx .r {
          padding-left: 0px;
          padding-right: 0px; } }
      .aboutPage .item1 .it1-bx .r::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        background-color: #fff;
        width: 70vw;
        height: 100%; }
        @media (max-width: 991px) {
          .aboutPage .item1 .it1-bx .r::before {
            width: 100%; } }
        @media (max-width: 570px) {
          .aboutPage .item1 .it1-bx .r::before {
            width: 100vw;
            left: -24px; } }
      .aboutPage .item1 .it1-bx .r .sh-des {
        position: relative;
        z-index: 1; }
      .aboutPage .item1 .it1-bx .r .des1 {
        margin-bottom: 40px; }
        @media (max-width: 1199px) {
          .aboutPage .item1 .it1-bx .r .des1 {
            margin-bottom: 12px; } }
        @media (max-width: 767px) {
          .aboutPage .item1 .it1-bx .r .des1 {
            margin-bottom: 30px; } }
      .aboutPage .item1 .it1-bx .r .des2 span {
        font-weight: 700;
        color: #1d2088; }

.aboutPage .item2 {
  padding-top: 130px;
  margin-bottom: 100px;
  position: relative; }
  @media (max-width: 767px) {
    .aboutPage .item2 {
      padding-top: 80px;
      margin-bottom: 25px; } }
  .aboutPage .item2 .bg1 {
    position: absolute;
    right: 0;
    top: 0;
    width: 40vw;
    height: 100%;
    background: url(../images/bg1.png?1fea62b0); }
    @media (max-width: 767px) {
      .aboutPage .item2 .bg1 {
        background-size: contain;
        width: 52vw;
        height: 75%; } }
  .aboutPage .item2 .it2-bx {
    display: flex;
    position: relative;
    z-index: 3; }
    @media (max-width: 991px) {
      .aboutPage .item2 .it2-bx {
        display: block; } }
    .aboutPage .item2 .it2-bx .img-bx {
      position: absolute;
      top: 0;
      left: 51%;
      width: 49.1vw; }
      @media (max-width: 991px) {
        .aboutPage .item2 .it2-bx .img-bx {
          width: 100%;
          left: auto;
          position: relative; } }
      .aboutPage .item2 .it2-bx .img-bx .bgcover {
        padding-bottom: 300px;
        background-size: cover !important; }
        @media (max-width: 991px) {
          .aboutPage .item2 .it2-bx .img-bx .bgcover {
            padding-bottom: 31.8%; } }
    .aboutPage .item2 .it2-bx .r {
      width: 48.5%; }
      @media (max-width: 991px) {
        .aboutPage .item2 .it2-bx .r {
          width: 100%;
          margin-top: 37px; } }
      .aboutPage .item2 .it2-bx .r .des1 {
        margin-bottom: 35px; }
        @media (max-width: 991px) {
          .aboutPage .item2 .it2-bx .r .des1 {
            margin-bottom: 31px; } }
        .aboutPage .item2 .it2-bx .r .des1 span {
          color: #1d2088;
          font-weight: 700; }
      .aboutPage .item2 .it2-bx .r .des2 {
        margin-bottom: 35px; }
        @media (max-width: 991px) {
          .aboutPage .item2 .it2-bx .r .des2 {
            margin-bottom: 31px; } }
      .aboutPage .item2 .it2-bx .r .des3 span {
        font-weight: 700;
        color: #1d2088; }

.aboutPage .item3 {
  padding-bottom: 35px;
  padding-top: 80px;
  position: relative; }
  @media (max-width: 991px) {
    .aboutPage .item3 {
      padding-top: 45px; } }
  .aboutPage .item3 .bg1 {
    position: absolute;
    left: 0;
    top: 0;
    background: url(../images/bg1.png?1fea62b0);
    width: 34.5vw;
    height: 100%; }
    @media (max-width: 991px) {
      .aboutPage .item3 .bg1 {
        width: 57vw;
        height: 43%;
        background-size: contain; } }
  .aboutPage .item3 .it3-bx {
    display: flex;
    position: relative;
    z-index: 8; }
    @media (max-width: 991px) {
      .aboutPage .item3 .it3-bx {
        display: block; } }
    .aboutPage .item3 .it3-bx .l {
      width: 40%; }
      @media (max-width: 991px) {
        .aboutPage .item3 .it3-bx .l {
          width: 100%; } }
      .aboutPage .item3 .it3-bx .l .bgcover {
        padding-bottom: 425px;
        background-size: cover !important; }
        @media (max-width: 991px) {
          .aboutPage .item3 .it3-bx .l .bgcover {
            padding-bottom: 86%; } }
    .aboutPage .item3 .it3-bx .r {
      width: 60%; }
      @media (max-width: 991px) {
        .aboutPage .item3 .it3-bx .r {
          width: 100%; } }
      .aboutPage .item3 .it3-bx .r .it3-list {
        max-width: 610px;
        padding-left: 10px;
        width: 100%;
        margin-left: auto;
        display: flex;
        flex-wrap: wrap; }
        @media (max-width: 991px) {
          .aboutPage .item3 .it3-bx .r .it3-list {
            display: block;
            margin-top: 25px; } }
        .aboutPage .item3 .it3-bx .r .it3-list li {
          width: 50%;
          display: flex;
          align-items: center;
          padding: 27px 0;
          padding-top: 29px;
          border-bottom: 1px solid #e5e5e5; }
          @media (max-width: 991px) {
            .aboutPage .item3 .it3-bx .r .it3-list li {
              width: 100%;
              padding: 12px 0;
              border: none; }
              .aboutPage .item3 .it3-bx .r .it3-list li:not(:last-child) {
                border-bottom: 1px solid #e5e5e5; } }
          @media (min-width: 992px) {
            .aboutPage .item3 .it3-bx .r .it3-list li:first-child {
              padding-top: 8px; }
            .aboutPage .item3 .it3-bx .r .it3-list li:nth-child(2) {
              padding-top: 8px; }
            .aboutPage .item3 .it3-bx .r .it3-list li:last-child {
              padding-bottom: 8px;
              border-bottom: none; }
            .aboutPage .item3 .it3-bx .r .it3-list li:nth-last-child(2) {
              padding-bottom: 8px;
              border-bottom: none; }
            .aboutPage .item3 .it3-bx .r .it3-list li:nth-child(odd) {
              border-right: 1px solid #e5e5e5; }
            .aboutPage .item3 .it3-bx .r .it3-list li:nth-child(even) {
              padding-left: 2%; } }
          .aboutPage .item3 .it3-bx .r .it3-list li .img-bx {
            min-width: 62px;
            width: 62px;
            margin-right: 20px; }
            @media (max-width: 991px) {
              .aboutPage .item3 .it3-bx .r .it3-list li .img-bx {
                width: 70px;
                min-width: 70px;
                margin-right: 32px; } }
          .aboutPage .item3 .it3-bx .r .it3-list li .card .t {
            font-size: 1.25rem;
            line-height: 1.6;
            letter-spacing: 0.5px;
            font-weight: 500;
            color: #000; }
            @media (max-width: 991px) {
              .aboutPage .item3 .it3-bx .r .it3-list li .card .t {
                margin-bottom: 3px; } }
          .aboutPage .item3 .it3-bx .r .it3-list li .card .sh-des .be {
            position: relative;
            min-width: 106px;
            width: 106px;
            padding-right: 24px;
            margin-right: 5px; }
            .aboutPage .item3 .it3-bx .r .it3-list li .card .sh-des .be::before {
              position: absolute;
              content: '';
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 24px;
              height: 3px;
              background: url(../images/about/arrow.png) no-repeat center center/contain; }
      .aboutPage .item3 .it3-bx .r .ver {
        max-width: 610px;
        padding-left: 10px;
        width: 100%;
        margin-left: auto;
        font-size: 0.875rem;
        line-height: 2.1;
        letter-spacing: 0.35px;
        font-weight: 300;
        color: #6f6f6f;
        margin-top: 10px; }
        @media (max-width: 991px) {
          .aboutPage .item3 .it3-bx .r .ver {
            margin-top: 5px; } }

.aboutPage .item4 {
  padding-top: 160px;
  margin-bottom: 75px;
  padding-bottom: 140px; }
  @media (max-width: 991px) {
    .aboutPage .item4 {
      padding-top: 30px;
      padding-bottom: 20px; } }
  .aboutPage .item4 .it4-bx {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 3; }
    @media (max-width: 991px) {
      .aboutPage .item4 .it4-bx {
        display: block; } }
    .aboutPage .item4 .it4-bx .img-bx {
      position: absolute;
      left: 51%;
      width: 49.1vw; }
      @media (max-width: 991px) {
        .aboutPage .item4 .it4-bx .img-bx {
          width: 100%;
          left: auto;
          position: relative;
          margin-bottom: 30px; } }
      .aboutPage .item4 .it4-bx .img-bx::before {
        position: absolute;
        content: '';
        bottom: -65px;
        right: 0;
        height: 65px;
        width: 80%;
        background: #fff; }
        @media (max-width: 991px) {
          .aboutPage .item4 .it4-bx .img-bx::before {
            bottom: -25px;
            right: -25px;
            height: calc(100% + 50px);
            width: 60%;
            z-index: -1; } }
      .aboutPage .item4 .it4-bx .img-bx::after {
        position: absolute;
        content: '';
        height: 150px;
        width: 55%;
        bottom: -215px;
        right: 0;
        background: url(../images/bg1.png?1fea62b0); }
        @media (max-width: 991px) {
          .aboutPage .item4 .it4-bx .img-bx::after {
            display: none; } }
      .aboutPage .item4 .it4-bx .img-bx .bgcover {
        padding-bottom: 530px;
        background-size: cover !important; }
        @media (max-width: 991px) {
          .aboutPage .item4 .it4-bx .img-bx .bgcover {
            padding-bottom: 56.1%; } }
    .aboutPage .item4 .it4-bx .card {
      width: 48.5%; }
      @media (max-width: 991px) {
        .aboutPage .item4 .it4-bx .card {
          width: 100%; } }
      .aboutPage .item4 .it4-bx .card .bl {
        color: #1d2088;
        font-weight: 500; }
      .aboutPage .item4 .it4-bx .card .sh-des {
        margin-bottom: 33px; }
        @media (max-width: 991px) {
          .aboutPage .item4 .it4-bx .card .sh-des {
            margin-bottom: 25px; } }
      .aboutPage .item4 .it4-bx .card .des5 {
        margin-bottom: 0; }
      @media (max-width: 991px) {
        .aboutPage .item4 .it4-bx .card .img-bx::before {
          display: none; } }
      @media (max-width: 991px) {
        .aboutPage .item4 .it4-bx .card .bgcover {
          padding-bottom: 56.1%;
          background-size: cover !important; } }

.aboutPage .sec-bx {
  position: relative; }
  .aboutPage .sec-bx .since-bx {
    position: absolute;
    left: 60px;
    top: 70px; }
    @media (max-width: 991px) {
      .aboutPage .sec-bx .since-bx {
        display: none; } }

.refuelPage .sec-bx {
  position: relative; }
  .refuelPage .sec-bx::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    height: 100%;
    background: url(../images/bg1.png?1fea62b0);
    width: 195px;
    z-index: -1; }
    @media (max-width: 767px) {
      .refuelPage .sec-bx::before {
        display: none; } }
  .refuelPage .sec-bx::after {
    position: absolute;
    content: '';
    right: 186px;
    bottom: -3px;
    width: 320px;
    height: 170px;
    background: url(../images/bg1.png?1fea62b0);
    z-index: -1; }
    @media (max-width: 767px) {
      .refuelPage .sec-bx::after {
        display: none; } }

.refuelPage .item1 {
  padding-top: 120px; }
  @media (max-width: 767px) {
    .refuelPage .item1 {
      padding-top: 75px; } }
  @media (max-width: 767px) {
    .refuelPage .item1 .it1-bx .sh-lti {
      margin-bottom: 15px; } }
  .refuelPage .item1 .it1-bx .it1-list {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .refuelPage .item1 .it1-bx .it1-list {
        max-width: 86%;
        margin: 0 auto;
        margin-top: 10px; } }
    @media (max-width: 370px) {
      .refuelPage .item1 .it1-bx .it1-list {
        max-width: 100%; } }
    .refuelPage .item1 .it1-bx .it1-list li {
      width: 23.5%;
      display: flex;
      align-items: center;
      height: 80px;
      justify-content: space-between;
      padding-right: 15px;
      padding-left: 21px;
      padding-top: 8px; }
      @media (max-width: 1199px) {
        .refuelPage .item1 .it1-bx .it1-list li {
          width: 47%;
          margin-bottom: 15px; }
          .refuelPage .item1 .it1-bx .it1-list li:nth-child(odd) {
            margin-right: 4%; } }
      @media (max-width: 767px) {
        .refuelPage .item1 .it1-bx .it1-list li {
          padding-top: 16px;
          padding-bottom: 10px;
          height: 90px;
          display: block;
          text-align: center; } }
      @media (max-width: 370px) {
        .refuelPage .item1 .it1-bx .it1-list li {
          padding-left: 5px;
          padding-right: 5px; } }
      .refuelPage .item1 .it1-bx .it1-list li:nth-child(1) {
        background-color: #6a50c8; }
      .refuelPage .item1 .it1-bx .it1-list li:nth-child(2) {
        background-color: #f29f22; }
      .refuelPage .item1 .it1-bx .it1-list li:nth-child(3) {
        background-color: #69bb4e; }
      .refuelPage .item1 .it1-bx .it1-list li:nth-child(4) {
        background-color: #3dbae0; }
      .refuelPage .item1 .it1-bx .it1-list li .t {
        font-size: 1.125rem;
        line-height: 2.3;
        letter-spacing: 1.8px;
        font-weight: 500;
        color: #fff;
        order: 1; }
        @media (max-width: 767px) {
          .refuelPage .item1 .it1-bx .it1-list li .t {
            font-size: 0.9375rem;
            line-height: 2; } }
      .refuelPage .item1 .it1-bx .it1-list li .pric {
        font-size: 1.125rem;
        line-height: 3.5;
        letter-spacing: 1.8px;
        font-weight: 500;
        color: #fff;
        order: 2; }
        @media (max-width: 767px) {
          .refuelPage .item1 .it1-bx .it1-list li .pric {
            font-size: 0.9375rem;
            line-height: 1.3; } }
        .refuelPage .item1 .it1-bx .it1-list li .pric span {
          font-size: 2.375rem;
          line-height: 1.8;
          letter-spacing: 0.95px;
          font-weight: 500;
          color: #fff; }
          @media (max-width: 767px) {
            .refuelPage .item1 .it1-bx .it1-list li .pric span {
              font-size: 1.9375rem;
              line-height: 1.1;
              font-weight: 400; } }
      @media (min-width: 1200px) {
        .refuelPage .item1 .it1-bx .it1-list li:not(:last-child) {
          margin-right: 2%; } }
  .refuelPage .item1 .it1-bx .oil-da {
    font-size: 1rem;
    line-height: 2;
    letter-spacing: 1.6px;
    font-weight: 400;
    color: #1d2088; }
  .refuelPage .item1 .it1-bx .flex-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px; }
    @media (max-width: 767px) {
      .refuelPage .item1 .it1-bx .flex-bx {
        margin-top: 0px; } }
    .refuelPage .item1 .it1-bx .flex-bx .un {
      font-size: 1rem;
      line-height: 2;
      letter-spacing: 1.6px;
      font-weight: 400;
      color: #1d2088; }

.refuelPage .item2 {
  margin-top: 115px;
  position: relative; }
  @media (max-width: 767px) {
    .refuelPage .item2 {
      margin-top: 65px; } }
  .refuelPage .item2::before {
    position: absolute;
    content: '';
    height: 70%;
    width: 100vw;
    left: 0;
    bottom: 52px;
    background-color: #e6e6e6; }
    @media (max-width: 767px) {
      .refuelPage .item2::before {
        display: none; } }
  .refuelPage .item2 .it2-bx {
    position: relative; }
    .refuelPage .item2 .it2-bx .img-bx {
      position: absolute;
      left: 58.1%;
      bottom: 0;
      width: 45.3vw; }
      @media (max-width: 991px) {
        .refuelPage .item2 .it2-bx .img-bx {
          position: relative;
          left: auto;
          bottom: auto;
          width: 100%; } }
      @media (max-width: 450px) {
        .refuelPage .item2 .it2-bx .img-bx {
          width: calc(100% + 48px);
          left: -24px; } }
      .refuelPage .item2 .it2-bx .img-bx .bgcover {
        padding-bottom: 380px;
        background-size: cover !important; }
        @media (max-width: 767px) {
          .refuelPage .item2 .it2-bx .img-bx .bgcover {
            padding-bottom: 43.6%; } }
    .refuelPage .item2 .it2-bx .card {
      width: 58.1%; }
      @media (max-width: 991px) {
        .refuelPage .item2 .it2-bx .card {
          width: 100%;
          padding-top: 40px; } }
      @media (max-width: 767px) {
        .refuelPage .item2 .it2-bx .card {
          position: relative; }
          .refuelPage .item2 .it2-bx .card::before {
            position: absolute;
            content: '';
            width: calc(100% + 48px);
            left: -24px;
            top: 0;
            height: calc(100% - 48px);
            background-color: #e6e6e6;
            z-index: -1; } }
      .refuelPage .item2 .it2-bx .card .sh-lti {
        margin-bottom: 92px; }
        @media (max-width: 991px) {
          .refuelPage .item2 .it2-bx .card .sh-lti {
            margin-bottom: 24px; } }
      .refuelPage .item2 .it2-bx .card .sh-des {
        max-width: 590px;
        width: 98%;
        text-align: justify; }
        @media (max-width: 1199px) {
          .refuelPage .item2 .it2-bx .card .sh-des {
            width: 90%; } }
        @media (max-width: 991px) {
          .refuelPage .item2 .it2-bx .card .sh-des {
            line-height: 1.5; } }
      .refuelPage .item2 .it2-bx .card .des1 {
        margin-bottom: 40px; }
        @media (max-width: 991px) {
          .refuelPage .item2 .it2-bx .card .des1 {
            margin-bottom: 30px; } }
      .refuelPage .item2 .it2-bx .card .des2 {
        margin-bottom: 40px; }
      .refuelPage .item2 .it2-bx .card .bl-bx {
        background-color: #1d2088;
        padding: 28px 20px; }
        @media (max-width: 767px) {
          .refuelPage .item2 .it2-bx .card .bl-bx {
            padding: 18px 15px;
            width: calc(100% + 24px); } }
        .refuelPage .item2 .it2-bx .card .bl-bx .des {
          padding-left: 61px;
          position: relative;
          font-size: 1rem;
          line-height: 2;
          letter-spacing: 1.6px;
          font-weight: 300;
          color: #fff; }
          @media (max-width: 767px) {
            .refuelPage .item2 .it2-bx .card .bl-bx .des {
              line-height: 1.65;
              padding-left: 41px; } }
          .refuelPage .item2 .it2-bx .card .bl-bx .des img {
            position: absolute;
            width: 40px;
            top: 50%;
            left: 0;
            transform: translateY(-50%); }
            @media (max-width: 767px) {
              .refuelPage .item2 .it2-bx .card .bl-bx .des img {
                width: 30px; } }

.refuelPage .item3 {
  padding: 120px 0; }
  @media (max-width: 767px) {
    .refuelPage .item3 {
      padding: 70px 0;
      padding-bottom: 40px; } }
  .refuelPage .item3 .it3-list {
    margin-top: 60px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .refuelPage .item3 .it3-list {
        flex-wrap: wrap; } }
    @media (max-width: 767px) {
      .refuelPage .item3 .it3-list {
        margin-top: 25px; } }
    .refuelPage .item3 .it3-list li {
      text-align: center; }
      @media (max-width: 991px) {
        .refuelPage .item3 .it3-list li {
          width: 33%;
          margin-bottom: 25px; } }
      @media (max-width: 767px) {
        .refuelPage .item3 .it3-list li {
          width: 50%; } }
      .refuelPage .item3 .it3-list li .sh-des {
        margin-top: 15px; }
        @media (max-width: 767px) {
          .refuelPage .item3 .it3-list li .sh-des {
            margin-top: 2px; } }
      .refuelPage .item3 .it3-list li .img-bx {
        width: 90px;
        height: 90px;
        margin: 0 auto;
        position: relative; }
        .refuelPage .item3 .it3-list li .img-bx img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .refuelPage .item3 .it3-list li:nth-child(1) .img-bx img {
        width: 90px;
        height: 90px; }
        @media (max-width: 767px) {
          .refuelPage .item3 .it3-list li:nth-child(1) .img-bx img {
            width: 74px;
            height: 74px; } }
      .refuelPage .item3 .it3-list li:nth-child(2) .img-bx img {
        width: 80px;
        height: 32px; }
        @media (max-width: 767px) {
          .refuelPage .item3 .it3-list li:nth-child(2) .img-bx img {
            width: 66px;
            height: 26px; } }
      .refuelPage .item3 .it3-list li:nth-child(3) .img-bx img {
        width: 73px;
        height: 31px; }
        @media (max-width: 767px) {
          .refuelPage .item3 .it3-list li:nth-child(3) .img-bx img {
            width: 60px;
            height: 26px; } }
      .refuelPage .item3 .it3-list li:nth-child(4) .img-bx {
        width: 225px; }
        @media (max-width: 767px) {
          .refuelPage .item3 .it3-list li:nth-child(4) .img-bx {
            width: 162px; } }
        @media (max-width: 350px) {
          .refuelPage .item3 .it3-list li:nth-child(4) .img-bx {
            width: 140px; } }
      .refuelPage .item3 .it3-list li:nth-child(5) .img-bx img {
        width: 63px;
        height: 61px; }
        @media (max-width: 767px) {
          .refuelPage .item3 .it3-list li:nth-child(5) .img-bx img {
            width: 52px;
            height: 50px; } }
      .refuelPage .item3 .it3-list li:nth-child(6) .img-bx img {
        width: 54px;
        height: 53px; }
        @media (max-width: 767px) {
          .refuelPage .item3 .it3-list li:nth-child(6) .img-bx img {
            width: 45px;
            height: 43px; } }

.inspectionPage .item1 {
  padding-top: 120px;
  padding-bottom: 140px;
  position: relative; }
  @media (max-width: 991px) {
    .inspectionPage .item1 {
      padding-top: 75px;
      padding-bottom: 14%; } }
  .inspectionPage .item1::before {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    width: 150px;
    height: 140px;
    background: url(../images/bg1.png?1fea62b0); }
    @media (max-width: 767px) {
      .inspectionPage .item1::before {
        width: 100%;
        height: 39px;
        background-size: cover; } }
  .inspectionPage .item1 .it1-bx {
    margin-top: -35px;
    position: relative;
    height: 430px;
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .inspectionPage .item1 .it1-bx {
        display: block;
        margin-top: 15px; } }
    .inspectionPage .item1 .it1-bx::before {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      left: -335px;
      background-color: #fff;
      width: 100vw;
      height: calc(100% - 150px); }
      @media (max-width: 991px) {
        .inspectionPage .item1 .it1-bx::before {
          display: none; } }
    .inspectionPage .item1 .it1-bx::after {
      position: absolute;
      content: '';
      top: 50%;
      left: -335px;
      transform: translateY(-50%);
      width: 260px;
      height: calc(100% - 150px);
      background: url(../images/bg1.png?1fea62b0); }
    .inspectionPage .item1 .it1-bx .img-bx {
      position: relative;
      left: 51.3%;
      width: 49.1vw;
      order: 2; }
      @media (max-width: 991px) {
        .inspectionPage .item1 .it1-bx .img-bx {
          left: -25px;
          width: calc(100% + 50px); } }
      .inspectionPage .item1 .it1-bx .img-bx .bgcover {
        padding-bottom: 45.5%;
        background-size: cover !important; }
    .inspectionPage .item1 .it1-bx .des-bx {
      position: absolute;
      width: 51.3%;
      padding-right: 2%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      order: 1; }
      @media (max-width: 991px) {
        .inspectionPage .item1 .it1-bx .des-bx {
          position: relative;
          width: 100%;
          top: auto;
          transform: none;
          padding-right: 0;
          margin-top: 40px; } }
      .inspectionPage .item1 .it1-bx .des-bx .se-btn {
        width: 148px;
        height: 60px;
        border: solid 1px #b5b5b5;
        text-align: center;
        display: block;
        margin-top: 42px;
        font-size: 1rem;
        line-height: 3.8;
        letter-spacing: 0.8px;
        font-weight: 300;
        color: #000;
        position: relative; }
        @media (max-width: 991px) {
          .inspectionPage .item1 .it1-bx .des-bx .se-btn {
            margin-top: 32px;
            height: 44px;
            line-height: 2.8; } }
        .inspectionPage .item1 .it1-bx .des-bx .se-btn::before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 0;
          height: 3px;
          background-color: #1d2088;
          transition: .4s ease; }
        .inspectionPage .item1 .it1-bx .des-bx .se-btn span {
          position: relative;
          padding-left: 32px; }
          .inspectionPage .item1 .it1-bx .des-bx .se-btn span img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 25px; }
        @media (min-width: 768px) {
          .inspectionPage .item1 .it1-bx .des-bx .se-btn:hover::before {
            width: 100%; } }

.inspectionPage .item2 {
  padding-bottom: 95px;
  position: relative; }
  @media (max-width: 991px) {
    .inspectionPage .item2 {
      padding-top: 75px;
      padding-bottom: 70px; } }
  .inspectionPage .item2 .bg1 {
    width: 505px;
    height: calc(100% - 165px);
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff; }
    @media (max-width: 767px) {
      .inspectionPage .item2 .bg1 {
        display: none; } }
  .inspectionPage .item2 .bg2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 430px;
    height: 165px;
    background: url(../images/bg1.png?1fea62b0); }
    @media (max-width: 767px) {
      .inspectionPage .item2 .bg2 {
        display: none; } }
  .inspectionPage .item2 .it2-list {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative; }
    @media (max-width: 767px) {
      .inspectionPage .item2 .it2-list {
        margin-top: 35px; }
        .inspectionPage .item2 .it2-list::before {
          position: absolute;
          content: "";
          top: -20px;
          left: -25px;
          width: 110px;
          height: calc(100% + 20px);
          background-color: #fff;
          z-index: -1; }
        .inspectionPage .item2 .it2-list::after {
          position: absolute;
          content: "";
          top: 50%;
          transform: translateY(-50%);
          right: -25px;
          width: calc(100% - 60px);
          height: 70%;
          background: url(../images/bg1.png?1fea62b0);
          background-size: contain;
          z-index: -1; } }
    .inspectionPage .item2 .it2-list li {
      width: 23%;
      margin-bottom: 60px; }
      @media (max-width: 767px) {
        .inspectionPage .item2 .it2-list li {
          width: 46%;
          margin-bottom: 30px; } }
      .inspectionPage .item2 .it2-list li .img-bx {
        width: 95px;
        height: 95px;
        margin: 0 auto; }
        @media (max-width: 767px) {
          .inspectionPage .item2 .it2-list li .img-bx {
            width: 75px;
            height: 75px; } }
      .inspectionPage .item2 .it2-list li .des {
        text-align: center;
        font-size: 1rem;
        line-height: 1.63;
        letter-spacing: 0.4px;
        font-weight: 300;
        color: #000;
        position: relative;
        padding-top: 16px; }
        .inspectionPage .item2 .it2-list li .des::before {
          position: absolute;
          content: '';
          top: 4px;
          left: 0;
          height: 1px;
          width: 100%;
          background-color: #1d2088; }
        .inspectionPage .item2 .it2-list li .des::after {
          position: absolute;
          content: '';
          top: 1px;
          left: 0;
          height: 3px;
          width: 56px;
          background-color: #1d2088; }
          @media (max-width: 767px) {
            .inspectionPage .item2 .it2-list li .des::after {
              top: 2px;
              width: 30px; } }

.inspectionPage .item3 .container {
  position: relative; }
  @media (max-width: 991px) {
    .inspectionPage .item3 .container {
      padding-bottom: 130px; } }
  .inspectionPage .item3 .container::before {
    position: absolute;
    content: '';
    bottom: 0px;
    left: -165px;
    background: url(../images/inspection/6_img.png) no-repeat center center/contain;
    width: 379px;
    height: 157px; }
    @media (max-width: 991px) {
      .inspectionPage .item3 .container::before {
        left: 5px;
        width: 254px;
        height: 92px; } }

.inspectionPage .item3 .it3-bx {
  margin-top: 25px;
  display: flex; }
  @media (max-width: 991px) {
    .inspectionPage .item3 .it3-bx {
      display: block;
      margin-top: 15px; } }
  .inspectionPage .item3 .it3-bx .l {
    min-width: 253px;
    width: 253px;
    position: relative; }
    @media (max-width: 991px) {
      .inspectionPage .item3 .it3-bx .l {
        min-width: 100%;
        width: 100%; } }
    .inspectionPage .item3 .it3-bx .l::before {
      position: absolute;
      content: '';
      left: -350px;
      top: 120px;
      width: 478px;
      height: 100%;
      background: url(../images/bg1.png?1fea62b0);
      z-index: -1; }
      @media (max-width: 991px) {
        .inspectionPage .item3 .it3-bx .l::before {
          display: none; } }
    .inspectionPage .item3 .it3-bx .l .da-bx {
      padding-bottom: 35px;
      padding-top: 25px;
      margin-bottom: 20px;
      position: relative; }
      @media (max-width: 991px) {
        .inspectionPage .item3 .it3-bx .l .da-bx {
          padding-bottom: 14px;
          padding-top: 15px; } }
      .inspectionPage .item3 .it3-bx .l .da-bx::before {
        position: absolute;
        content: '';
        left: -347px;
        width: 600px;
        height: 100%;
        top: 0;
        background-color: #e6e6e6;
        z-index: -1; }
        @media (max-width: 991px) {
          .inspectionPage .item3 .it3-bx .l .da-bx::before {
            left: -25px;
            width: calc(100% + 50px);
            height: 100%; } }
      .inspectionPage .item3 .it3-bx .l .da-bx .da {
        display: flex;
        font-size: 1rem;
        line-height: 1.8;
        letter-spacing: 1.6px;
        font-weight: 300;
        color: #000; }
        .inspectionPage .item3 .it3-bx .l .da-bx .da i {
          min-width: 105px;
          width: 105px;
          display: block;
          position: relative; }
          .inspectionPage .item3 .it3-bx .l .da-bx .da i::before {
            position: absolute;
            content: '';
            right: 10px;
            width: 1px;
            top: 6px;
            height: 15px;
            background-color: #000; }
    @media (max-width: 991px) {
      .inspectionPage .item3 .it3-bx .l .img-obx {
        display: flex;
        justify-content: space-between; }
        .inspectionPage .item3 .it3-bx .l .img-obx .img-bx {
          width: 47%; } }
    .inspectionPage .item3 .it3-bx .l .bx1 {
      margin-bottom: 8px; }
  .inspectionPage .item3 .it3-bx .r {
    padding-left: 5.5%;
    width: calc(100% - 253px);
    padding-top: 25px; }
    @media (max-width: 991px) {
      .inspectionPage .item3 .it3-bx .r {
        width: 100%;
        padding-left: 0; } }
    .inspectionPage .item3 .it3-bx .r .ti {
      font-size: 1rem;
      line-height: 2;
      letter-spacing: 1.6px;
      font-weight: 700;
      color: #000;
      margin-bottom: 5px; }
      @media (max-width: 991px) {
        .inspectionPage .item3 .it3-bx .r .ti {
          letter-spacing: .5px; } }
    .inspectionPage .item3 .it3-bx .r .t-list {
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid #e5e5e5; }
      @media (max-width: 991px) {
        .inspectionPage .item3 .it3-bx .r .t-list {
          padding-bottom: 28px;
          margin-bottom: 31px; } }
      .inspectionPage .item3 .it3-bx .r .t-list li {
        display: flex;
        align-items: center; }
        @media (max-width: 991px) {
          .inspectionPage .item3 .it3-bx .r .t-list li {
            align-items: baseline; } }
        .inspectionPage .item3 .it3-bx .r .t-list li:nth-child(4) {
          align-items: flex-start; }
          .inspectionPage .item3 .it3-bx .r .t-list li:nth-child(4) span {
            position: relative;
            top: 3px; }
        .inspectionPage .item3 .it3-bx .r .t-list li span {
          display: block;
          width: 32px;
          min-width: 32px;
          height: 32px;
          background-color: #1d2088;
          color: #fff;
          margin-right: 15px;
          text-align: center;
          font-size: 1rem;
          border-radius: 50%;
          line-height: 2; }
          @media (max-width: 991px) {
            .inspectionPage .item3 .it3-bx .r .t-list li span {
              width: 27px;
              min-width: 27px;
              height: 27px;
              line-height: 1.8; } }
        .inspectionPage .item3 .it3-bx .r .t-list li i {
          color: #1d2088;
          font-weight: 700; }
        .inspectionPage .item3 .it3-bx .r .t-list li .des {
          font-size: 1rem;
          line-height: 2.5;
          letter-spacing: 1.6px;
          font-weight: 300;
          color: #000; }
          @media (max-width: 991px) {
            .inspectionPage .item3 .it3-bx .r .t-list li .des {
              line-height: 2.2;
              letter-spacing: 0; } }
    .inspectionPage .item3 .it3-bx .r .bmark-ti {
      font-size: 1rem;
      line-height: 2;
      letter-spacing: 1.2px;
      font-weight: 300;
      color: #000; }
      .inspectionPage .item3 .it3-bx .r .bmark-ti span {
        color: #1d2088;
        margin-right: 5px; }
      @media (max-width: 991px) {
        .inspectionPage .item3 .it3-bx .r .bmark-ti {
          line-height: 1.8; } }
    .inspectionPage .item3 .it3-bx .r .flex-bx {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      @media (max-width: 991px) {
        .inspectionPage .item3 .it3-bx .r .flex-bx {
          margin-top: 6px; } }
      .inspectionPage .item3 .it3-bx .r .flex-bx div {
        border: 1px solid #a0a0a0;
        padding: 13px 15px;
        margin-bottom: 8px; }
        @media (max-width: 991px) {
          .inspectionPage .item3 .it3-bx .r .flex-bx div {
            padding: 7px 12px;
            letter-spacing: 0; } }
        .inspectionPage .item3 .it3-bx .r .flex-bx div:not(:last-child) {
          margin-right: 15px; }
          @media (max-width: 991px) {
            .inspectionPage .item3 .it3-bx .r .flex-bx div:not(:last-child) {
              margin-bottom: 10px; } }

.inspectionPage .item3 .res-btn {
  width: 148px;
  height: 60px;
  border: solid 1px #b5b5b5;
  font-size: 1rem;
  line-height: 3.88;
  letter-spacing: 0.8px;
  font-weight: 300;
  color: #000;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  position: relative;
  transition: .4s ease; }
  @media (max-width: 991px) {
    .inspectionPage .item3 .res-btn {
      width: 120px;
      height: 40px;
      line-height: 2.5; } }
  .inspectionPage .item3 .res-btn::before {
    position: absolute;
    width: 0;
    height: 4px;
    left: 0;
    bottom: 0;
    content: '';
    background-color: #1d2088;
    transition: .4s ease; }
  @media (min-width: 768px) {
    .inspectionPage .item3 .res-btn:hover {
      color: #1d2088; }
      .inspectionPage .item3 .res-btn:hover::before {
        width: 100%; } }
  .inspectionPage .item3 .res-btn span {
    position: relative;
    padding-left: 25px; }
    .inspectionPage .item3 .res-btn span img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 17px; }

.inspectionPage .item4 {
  padding-top: 120px;
  padding-bottom: 120px; }
  @media (max-width: 767px) {
    .inspectionPage .item4 {
      padding-top: 60px;
      padding-bottom: 80px; } }
  .inspectionPage .item4 .sh-lti {
    margin-bottom: 40px;
    text-align: center; }
    @media (max-width: 767px) {
      .inspectionPage .item4 .sh-lti {
        margin-bottom: 15px; } }

.washPage .item1 {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative; }
  @media (max-width: 991px) {
    .washPage .item1 {
      padding: 80px 0;
      padding-bottom: 70px; } }
  .washPage .item1::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -10px;
    width: 490px;
    height: 60%;
    background: url(../images/bg1.png?1fea62b0);
    z-index: -1; }
    @media (max-width: 767px) {
      .washPage .item1::before {
        display: none; } }

.washPage .it1-bx {
  display: flex;
  align-items: flex-end;
  margin-top: 50px;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .washPage .it1-bx {
      display: block;
      margin-top: 12px; } }
  .washPage .it1-bx .l {
    width: 48.7%; }
    @media (max-width: 991px) {
      .washPage .it1-bx .l {
        width: calc(100% + 50px);
        left: -25px;
        position: relative; } }
    .washPage .it1-bx .l .bgcover {
      padding-bottom: 490px;
      background-size: cover !important; }
      @media (max-width: 991px) {
        .washPage .it1-bx .l .bgcover {
          padding-bottom: 82%; } }
  .washPage .it1-bx .r {
    margin-bottom: 10px;
    width: 45.6%;
    padding-right: 3%;
    position: relative; }
    @media (max-width: 991px) {
      .washPage .it1-bx .r {
        width: 100%;
        padding-right: 0;
        padding-top: 40px; } }
    .washPage .it1-bx .r::before {
      position: absolute;
      content: '';
      bottom: 50px;
      width: 1500px;
      height: 100%;
      background-color: #fff;
      right: -360px;
      z-index: -1; }
      @media (max-width: 991px) {
        .washPage .it1-bx .r::before {
          position: absolute;
          top: 0;
          left: -25px;
          right: auto;
          bottom: auto;
          width: calc(100% + 50px);
          height: 88%;
          background: #e6e6e6; } }
    .washPage .it1-bx .r .sh-des {
      margin-bottom: 30px; }
      @media (max-width: 991px) {
        .washPage .it1-bx .r .sh-des {
          margin-bottom: 25px; } }
    .washPage .it1-bx .r .bl-bx {
      background-color: #1d2088;
      padding: 28px 20px; }
      @media (max-width: 767px) {
        .washPage .it1-bx .r .bl-bx {
          padding: 18px 15px;
          margin-top: 35px;
          width: calc(100% + 24px); } }
      .washPage .it1-bx .r .bl-bx .des {
        padding-left: 61px;
        position: relative;
        font-size: 1rem;
        line-height: 2;
        letter-spacing: 1.6px;
        font-weight: 300;
        color: #fff; }
        @media (max-width: 767px) {
          .washPage .it1-bx .r .bl-bx .des {
            line-height: 1.65;
            padding-left: 41px; } }
        .washPage .it1-bx .r .bl-bx .des img {
          position: absolute;
          width: 40px;
          top: 50%;
          left: 0;
          transform: translateY(-50%); }
          @media (max-width: 767px) {
            .washPage .it1-bx .r .bl-bx .des img {
              width: 30px; } }

.commodityPage .item1 {
  margin-top: 110px;
  position: relative;
  z-index: 5; }
  @media (max-width: 767px) {
    .commodityPage .item1 {
      margin-top: 70px; } }

.commodityPage .item2 {
  padding-bottom: 120px;
  position: relative; }
  .commodityPage .item2 .bg1 {
    position: absolute;
    top: -170px;
    right: 0;
    width: 585px;
    height: 95%;
    background: url(../images/bg1.png?1fea62b0);
    z-index: -1; }
    @media (max-width: 767px) {
      .commodityPage .item2 .bg1 {
        top: -130px;
        width: 45%;
        background-size: contain; } }
  @media (max-width: 767px) {
    .commodityPage .item2 {
      padding-bottom: 65px; } }
  .commodityPage .item2 .it2-bx {
    margin-top: 75px; }
    @media (max-width: 767px) {
      .commodityPage .item2 .it2-bx {
        margin-top: 50px; } }
    .commodityPage .item2 .it2-bx .sh-lti {
      margin-bottom: 45px; }
      @media (max-width: 767px) {
        .commodityPage .item2 .it2-bx .sh-lti {
          margin-bottom: 25px; } }

.contactPage .item1 {
  position: relative;
  margin-top: 120px;
  margin-bottom: 160px; }
  .contactPage .item1::before {
    position: absolute;
    content: '';
    background: url(../images/bg1.png);
    top: 24%;
    left: -130px;
    width: 970px;
    height: 85%; }
    @media (max-width: 991px) {
      .contactPage .item1::before {
        display: none; } }
  @media (max-width: 991px) {
    .contactPage .item1 {
      margin-top: 80px;
      margin-bottom: 110px; } }
  .contactPage .item1 .container {
    position: relative; }
  .contactPage .item1 .bg1 {
    position: absolute;
    right: 0;
    top: 0;
    width: calc((100% - 1232px) / 2);
    height: 100%;
    background-color: #fff; }
    @media (max-width: 1199px) {
      .contactPage .item1 .bg1 {
        width: calc((100% - 896px) / 2); } }
    @media (max-width: 991px) {
      .contactPage .item1 .bg1 {
        width: 50%; } }
    .contactPage .item1 .bg1::before {
      position: absolute;
      content: '';
      right: 0;
      bottom: 0;
      height: 50%;
      width: 50%;
      background: url(../images/bg1.png); }
      @media (max-width: 991px) {
        .contactPage .item1 .bg1::before {
          display: none; } }

.contactPage .it1-obx {
  background-color: #fff;
  padding: 0 16px; }
  @media (max-width: 991px) {
    .contactPage .it1-obx {
      padding: 0 24px; } }

.contactPage .it1-bx {
  max-width: 1022px;
  margin: 0 auto;
  width: 100%;
  padding-top: 105px;
  padding-bottom: 70px; }
  @media (max-width: 991px) {
    .contactPage .it1-bx {
      padding-top: 65px;
      padding-bottom: 40px; } }
  .contactPage .it1-bx .t-bx {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .contactPage .it1-bx .t-bx {
        display: block; } }
    .contactPage .it1-bx .t-bx .l {
      max-width: 403px;
      width: 95%;
      padding-right: 15px; }
      @media (max-width: 991px) {
        .contactPage .it1-bx .t-bx .l {
          width: 100%;
          margin: 0 auto; } }
      .contactPage .it1-bx .t-bx .l .ti {
        font-size: 2.5rem;
        line-height: 1.45;
        letter-spacing: 4px;
        font-weight: 400;
        color: #000;
        margin-bottom: 22px; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .t-bx .l .ti {
            font-size: 2.1875rem;
            letter-spacing: .5px;
            margin-bottom: 7px; } }
      .contactPage .it1-bx .t-bx .l .des {
        font-size: 1rem;
        line-height: 1.7;
        letter-spacing: 0.8px;
        font-weight: 300;
        color: #000; }
      .contactPage .it1-bx .t-bx .l ul {
        margin-top: 45px; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .t-bx .l ul {
            margin-top: 25px; } }
        .contactPage .it1-bx .t-bx .l ul li {
          padding-left: 47px;
          position: relative; }
          .contactPage .it1-bx .t-bx .l ul li:not(:last-child) {
            margin-bottom: 17px; }
          .contactPage .it1-bx .t-bx .l ul li .img-bx {
            width: 32px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%); }
          .contactPage .it1-bx .t-bx .l ul li .des, .contactPage .it1-bx .t-bx .l ul li a {
            font-size: 1rem;
            line-height: 1.4;
            letter-spacing: 0.8px;
            font-weight: 300;
            color: #000; }
    .contactPage .it1-bx .t-bx .r {
      max-width: 495px;
      width: 95%;
      margin: 0 auto; }
      @media (max-width: 991px) {
        .contactPage .it1-bx .t-bx .r {
          width: 100%;
          margin-top: 45px; } }
      .contactPage .it1-bx .t-bx .r .form-bx label {
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 0.8px;
        font-weight: 500;
        color: #000;
        display: block;
        position: relative; }
        .contactPage .it1-bx .t-bx .r .form-bx label span {
          color: #1d2088;
          position: absolute;
          left: -8px;
          top: -3px; }
      .contactPage .it1-bx .t-bx .r .form-bx input {
        height: 32px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #a0a0a0;
        transition: .4s ease;
        position: relative; }
        .contactPage .it1-bx .t-bx .r .form-bx input:focus {
          border-color: #1d2088; }
      .contactPage .it1-bx .t-bx .r .form-bx .help-block {
        position: absolute;
        margin-top: 3px;
        height: 22px;
        font-size: 0.875rem;
        line-height: 1.4;
        letter-spacing: 0.7px;
        font-weight: 300;
        color: #c40000; }
      .contactPage .it1-bx .t-bx .r .form-bx .form-group {
        margin-bottom: 28px;
        position: relative; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .t-bx .r .form-bx .form-group {
            margin-bottom: 25px; } }
      .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .textarea-wrapper.textfocu {
        border-color: #1d2088; }
      .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .textarea-wrapper {
        height: 80px;
        overflow: hidden;
        border: none;
        border-bottom: 1px solid #a0a0a0;
        padding: 0px 0px 10px;
        margin-top: 0px;
        background-color: #fff;
        transition: .4s ease; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .textarea-wrapper {
            margin-top: 0; } }
        @media (max-width: 767px) {
          .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .textarea-wrapper {
            height: 115px; } }
        .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .textarea-wrapper textarea {
          min-height: 66px;
          height: 0;
          overflow: hidden;
          border-bottom: none;
          padding-top: 14px;
          padding-left: 0px;
          text-rendering: auto;
          -webkit-writing-mode: horizontal-tb !important;
          background-color: #fff;
          resize: none;
          border: none;
          font-family: "Noto Sans TC";
          cursor: text; }
          @media (max-width: 767px) {
            .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .textarea-wrapper textarea {
              min-height: 99px;
              padding-top: 8px; } }
      .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #1d2088;
        width: 3px;
        left: -2px;
        position: relative; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
            width: 3px; } }
      .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerRail {
        background-color: #b4b4b4;
        width: 2px; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerRail {
            width: 1px; } }
      .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .mCSB_scrollTools {
        right: -5px; }
      .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .mCSB_inside > .mCSB_container {
        margin-right: 13px; }
      .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerContainer {
        top: 15px;
        left: -3px; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .t-bx .r .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerContainer {
            top: 6px; } }
      .contactPage .it1-bx .t-bx .r .form-bx .send-btn {
        width: 185px;
        height: 61px;
        border: solid 1px #a0a0a0;
        text-align: center;
        display: block;
        font-size: 1rem;
        line-height: 3.9;
        letter-spacing: 0.8px;
        font-weight: 300;
        color: #000;
        position: relative;
        margin-top: 8px; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .t-bx .r .form-bx .send-btn {
            width: 120px;
            height: 40px;
            line-height: 2.6;
            margin: 0 auto;
            margin-top: 22px; } }
        .contactPage .it1-bx .t-bx .r .form-bx .send-btn::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 0;
          height: 4px;
          background-color: #1d2088;
          transition: .4s ease; }
        @media (min-width: 768px) {
          .contactPage .it1-bx .t-bx .r .form-bx .send-btn:hover::before {
            width: 100%; } }
  .contactPage .it1-bx .b-bx {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991px) {
      .contactPage .it1-bx .b-bx {
        display: block;
        margin-top: 70px; } }
    .contactPage .it1-bx .b-bx .img-bx {
      max-width: 490px;
      width: 96%;
      order: 1;
      position: relative; }
      @media (max-width: 1199px) {
        .contactPage .it1-bx .b-bx .img-bx {
          max-width: 395px; } }
      @media (max-width: 991px) {
        .contactPage .it1-bx .b-bx .img-bx {
          width: 100%;
          transform: scale(1.3);
          left: -10px;
          margin-top: 50px; } }
      .contactPage .it1-bx .b-bx .img-bx img {
        position: relative;
        left: -62px; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .b-bx .img-bx img {
            left: auto; } }
    .contactPage .it1-bx .b-bx .des-bx {
      order: 2;
      max-width: 495px;
      width: 95%;
      position: relative;
      top: -45px; }
      @media (max-width: 991px) {
        .contactPage .it1-bx .b-bx .des-bx {
          width: 100%;
          top: auto; } }
      @media (min-width: 1200px) {
        .contactPage .it1-bx .b-bx .des-bx {
          position: relative;
          left: -62px; } }
      .contactPage .it1-bx .b-bx .des-bx .ti {
        font-size: 2.5rem;
        line-height: 1.45;
        letter-spacing: 4px;
        font-weight: 400;
        color: #000;
        margin-bottom: 22px; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .b-bx .des-bx .ti {
            font-size: 2.1875rem;
            letter-spacing: .5px;
            margin-bottom: 7px; } }
      .contactPage .it1-bx .b-bx .des-bx .des {
        font-size: 1rem;
        line-height: 1.7;
        letter-spacing: 0.8px;
        font-weight: 300;
        color: #000;
        margin-bottom: 40px; }
      .contactPage .it1-bx .b-bx .des-bx .join-btn {
        display: block;
        width: 185px;
        height: 61px;
        background-color: #1d2088;
        text-align: center;
        font-size: 1rem;
        line-height: 3.9;
        letter-spacing: 0.8px;
        font-weight: 300;
        color: #fff;
        position: relative; }
        @media (max-width: 991px) {
          .contactPage .it1-bx .b-bx .des-bx .join-btn {
            width: 120px;
            height: 40px;
            line-height: 2.6;
            margin: 0 auto; } }
        .contactPage .it1-bx .b-bx .des-bx .join-btn::before {
          position: absolute;
          content: '';
          bottom: 3px;
          left: 0;
          width: 0;
          height: 4px;
          background-color: #fff;
          transition: .4s ease; }
        @media (min-width: 768px) {
          .contactPage .it1-bx .b-bx .des-bx .join-btn:hover::before {
            width: 100%; } }

.cooperationPage .sh-inban .sh-des {
  margin-top: 25px; }
  @media (max-width: 767px) {
    .cooperationPage .sh-inban .sh-des {
      margin-top: 20px; } }

.cooperationPage .item1 {
  padding: 120px 0; }
  @media (max-width: 991px) {
    .cooperationPage .item1 {
      padding: 80px 0;
      padding-bottom: 105px; } }
  .cooperationPage .item1 .it1-bx ul li {
    position: relative; }
  .cooperationPage .item1 .it1-bx ul li a {
    display: flex;
    position: relative; }
    @media (max-width: 991px) {
      .cooperationPage .item1 .it1-bx ul li a {
        display: block; } }
    .cooperationPage .item1 .it1-bx ul li a .img-bx {
      width: 74.3%;
      margin-left: auto; }
      @media (max-width: 991px) {
        .cooperationPage .item1 .it1-bx ul li a .img-bx {
          width: 100%; } }
      .cooperationPage .item1 .it1-bx ul li a .img-bx .bgcover {
        padding-bottom: 320px;
        background-size: cover !important; }
        @media (max-width: 991px) {
          .cooperationPage .item1 .it1-bx ul li a .img-bx .bgcover {
            padding-bottom: 35%; } }
    .cooperationPage .item1 .it1-bx ul li a .card {
      width: 390px;
      height: 177px;
      background-color: #e5e5e5;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 40px 50px; }
      @media (max-width: 991px) {
        .cooperationPage .item1 .it1-bx ul li a .card {
          position: relative;
          top: auto;
          transform: none;
          width: 100%;
          padding: 25px 24px;
          height: 130px; } }
      .cooperationPage .item1 .it1-bx ul li a .card .ti {
        font-size: 1.5rem;
        line-height: 1.25;
        letter-spacing: 2.4px;
        font-weight: 500;
        color: #000;
        height: 60px;
        position: relative;
        overflow: hidden; }
        @media (max-width: 991px) {
          .cooperationPage .item1 .it1-bx ul li a .card .ti {
            font-size: 1.25rem;
            height: auto;
            max-height: 53px;
            margin-bottom: 21px; } }
      .cooperationPage .item1 .it1-bx ul li a .card .redmore {
        font-size: 1rem;
        line-height: 1;
        letter-spacing: 0.8px;
        font-weight: 400;
        color: #000;
        padding-bottom: 17px;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        display: inline-block; }
        @media (max-width: 991px) {
          .cooperationPage .item1 .it1-bx ul li a .card .redmore {
            padding-bottom: 15px; } }
        .cooperationPage .item1 .it1-bx ul li a .card .redmore::before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 0;
          height: 4px;
          background-color: #1d2088;
          transition: .4s; }
    @media (min-width: 768px) {
      .cooperationPage .item1 .it1-bx ul li a:hover .card .redmore {
        color: #1d2088; }
      .cooperationPage .item1 .it1-bx ul li a:hover .card .redmore::before {
        width: 100%; }
      .cooperationPage .item1 .it1-bx ul li a:hover .card .ti {
        color: #1d2088; } }
  @media (min-width: 992px) {
    .cooperationPage .item1 .it1-bx ul li:nth-child(odd) a .img-bx {
      margin-left: 0; }
    .cooperationPage .item1 .it1-bx ul li:nth-child(odd) a .card {
      left: auto;
      right: 0; } }
  .cooperationPage .item1 .it1-bx ul li:not(:last-child) {
    margin-bottom: 95px; }
    @media (max-width: 991px) {
      .cooperationPage .item1 .it1-bx ul li:not(:last-child) {
        margin-bottom: 20px; } }
  .cooperationPage .item1 .it1-bx ul li:first-child, .cooperationPage .item1 .it1-bx ul li:nth-child(3) {
    z-index: 10; }
  .cooperationPage .item1 .it1-bx ul li:nth-child(4) {
    z-index: 9; }
  .cooperationPage .item1 .it1-bx ul li:first-child::after {
    position: absolute;
    content: '';
    right: -270px;
    top: 68%;
    transform: translateY(-50%);
    width: 735px;
    height: 600px;
    background: url(../images/bg2.png) no-repeat center center/contain;
    z-index: -1; }
    @media (max-width: 991px) {
      .cooperationPage .item1 .it1-bx ul li:first-child::after {
        top: 16%;
        width: 445px;
        height: 350px; } }
  .cooperationPage .item1 .it1-bx ul li:nth-child(2)::after {
    position: absolute;
    content: '';
    left: -270px;
    top: 68%;
    transform: translateY(-50%);
    width: 735px;
    height: 600px;
    background: url(../images/bg2.png) no-repeat center center/contain;
    z-index: -1;
    pointer-events: none; }
    @media (max-width: 991px) {
      .cooperationPage .item1 .it1-bx ul li:nth-child(2)::after {
        width: 445px;
        height: 350px;
        top: 6%;
        left: -290px; } }
  .cooperationPage .item1 .it1-bx ul li:nth-child(4)::after {
    position: absolute;
    content: '';
    right: -270px;
    top: 68%;
    transform: translateY(-50%);
    width: 735px;
    height: 600px;
    background: url(../images/bg2.png) no-repeat center center/contain;
    z-index: -1;
    pointer-events: none; }
    @media (max-width: 991px) {
      .cooperationPage .item1 .it1-bx ul li:nth-child(4)::after {
        top: 16%;
        width: 445px;
        height: 350px; } }
  .cooperationPage .item1 .it1-bx ul li:nth-child(5)::after {
    position: absolute;
    content: '';
    left: -270px;
    top: 68%;
    transform: translateY(-50%);
    width: 735px;
    height: 600px;
    background: url(../images/bg2.png) no-repeat center center/contain;
    z-index: -1;
    pointer-events: none; }
    @media (max-width: 991px) {
      .cooperationPage .item1 .it1-bx ul li:nth-child(5)::after {
        width: 445px;
        height: 350px;
        top: 6%;
        left: -290px; } }

.cooperationdetailPage .sh-inban .sh-des {
  margin-top: 25px; }
  @media (max-width: 767px) {
    .cooperationdetailPage .sh-inban .sh-des {
      margin-top: 20px; } }

.cooperationdetailPage .d-bg1 {
  position: absolute;
  left: 0;
  bottom: 0px;
  background: url(../images/bg1.png);
  width: 285px;
  height: calc(100% - 647px);
  z-index: -1; }
  @media (max-width: 767px) {
    .cooperationdetailPage .d-bg1 {
      display: none; } }

.cooperationdetailPage .d-bg2 {
  position: absolute;
  right: 0;
  bottom: 0px;
  background: url(../images/bg1.png);
  width: 590px;
  height: calc(100% - 852px);
  z-index: -1; }
  @media (max-width: 767px) {
    .cooperationdetailPage .d-bg2 {
      display: none; } }

.cooperationdetailPage .item1 {
  padding: 120px 0;
  position: relative; }
  @media (max-width: 991px) {
    .cooperationdetailPage .item1 {
      padding: 80px 0;
      padding-bottom: 75px;
      overflow: hidden; } }
  .cooperationdetailPage .item1 .editor_Content {
    position: relative; }
    @media (max-width: 767px) {
      .cooperationdetailPage .item1 .editor_Content::before {
        position: absolute;
        content: '';
        background: url(../images/bg1.png);
        background-size: contain;
        width: 319px;
        height: 251px;
        bottom: -56px;
        right: -200px;
        z-index: -1; } }
  .cooperationdetailPage .item1 .sh-lti {
    margin-bottom: 45px; }
    @media (max-width: 991px) {
      .cooperationdetailPage .item1 .sh-lti {
        margin-bottom: 15px; } }
    .cooperationdetailPage .item1 .sh-lti span {
      display: block; }
  .cooperationdetailPage .item1 .back-btn {
    display: block;
    margin: 0 auto;
    margin-top: 105px;
    width: 185px;
    height: 61px;
    border: solid 1px #a0a0a0;
    text-align: center;
    font-size: 1rem;
    line-height: 3.8;
    letter-spacing: 0.8px;
    font-weight: 300;
    color: #000;
    position: relative; }
    .cooperationdetailPage .item1 .back-btn::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 0;
      height: 4px;
      background-color: #1d2088;
      transition: .4s ease; }
    @media (min-width: 768px) {
      .cooperationdetailPage .item1 .back-btn:hover::before {
        width: 100%; } }
    @media (max-width: 991px) {
      .cooperationdetailPage .item1 .back-btn {
        margin-top: 65px;
        width: 120px;
        height: 40px;
        line-height: 2.5; } }

.newsPage .sec-bx {
  position: relative;
  background: url(../images/news/bg_point.png);
  background-repeat: repeat-y; }
  @media (max-width: 767px) {
    .newsPage .sec-bx {
      background: none; } }

.newsPage .item1 {
  padding-top: 110px; }
  @media (max-width: 767px) {
    .newsPage .item1 {
      padding-top: 70px; } }

.newsPage .item2 {
  margin-top: 155px;
  padding-bottom: 130px; }
  @media (max-width: 767px) {
    .newsPage .item2 {
      margin-top: 50px;
      padding-bottom: 100px; } }
  .newsPage .item2 .news-list li:nth-child(1) {
    position: relative; }
    .newsPage .item2 .news-list li:nth-child(1)::after {
      position: absolute;
      content: '';
      left: 54%;
      transform: translateX(-50%);
      top: 79%;
      width: 100vw;
      background: url(../images/news/monewbg.png) no-repeat center;
      height: 520px;
      z-index: -1;
      background-size: contain;
      pointer-events: none; }

.newsdetailPage .d-bg1 {
  position: absolute;
  left: 0;
  bottom: 0px;
  background: url(../images/bg1.png);
  width: 14.8%;
  height: 1010px;
  z-index: -1; }
  @media (max-width: 767px) {
    .newsdetailPage .d-bg1 {
      display: none; } }

.newsdetailPage .item1 {
  padding-top: 105px;
  position: relative; }
  @media (max-width: 767px) {
    .newsdetailPage .item1 {
      padding-top: 80px; } }
  .newsdetailPage .item1::before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    width: 30.7%;
    height: 834px;
    background: url(../images/bg1.png); }
    @media (max-width: 767px) {
      .newsdetailPage .item1::before {
        display: none; } }

.newsdetailPage .item2 {
  padding: 120px 0;
  padding-top: 100px;
  position: relative; }
  @media (max-width: 767px) {
    .newsdetailPage .item2 {
      padding-top: 52px;
      padding-bottom: 100px; } }
  .newsdetailPage .item2 .it2-bx {
    max-width: 1017px;
    width: 100%;
    margin: 0 auto; }
    .newsdetailPage .item2 .it2-bx .ti-bx {
      padding-left: 16px;
      position: relative;
      margin-bottom: 42px; }
      .newsdetailPage .item2 .it2-bx .ti-bx::before {
        position: absolute;
        content: '';
        top: 6px;
        left: 0;
        width: 1px;
        height: 91%;
        background-color: #1d2088; }
      .newsdetailPage .item2 .it2-bx .ti-bx .da {
        font-size: 1rem;
        line-height: 1.8;
        letter-spacing: 0.8px;
        font-weight: 400;
        color: #1d2088; }
      .newsdetailPage .item2 .it2-bx .ti-bx .ti {
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: 2.4px;
        font-weight: 500;
        color: #000; }
        @media (max-width: 767px) {
          .newsdetailPage .item2 .it2-bx .ti-bx .ti {
            font-size: 1.25rem;
            line-height: 1.3;
            letter-spacing: .5px; } }
  .newsdetailPage .item2 .editor_Content {
    position: relative; }
  .newsdetailPage .item2 .sh-lti {
    margin-bottom: 45px; }
    @media (max-width: 991px) {
      .newsdetailPage .item2 .sh-lti {
        margin-bottom: 15px; } }
    .newsdetailPage .item2 .sh-lti span {
      display: block; }
  .newsdetailPage .item2 .back-btn {
    display: block;
    margin: 0 auto;
    margin-top: 105px;
    width: 185px;
    height: 61px;
    border: solid 1px #a0a0a0;
    text-align: center;
    font-size: 1rem;
    line-height: 3.8;
    letter-spacing: 0.8px;
    font-weight: 300;
    color: #000;
    position: relative; }
    .newsdetailPage .item2 .back-btn::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 0;
      height: 4px;
      background-color: #1d2088;
      transition: .4s ease; }
    @media (min-width: 768px) {
      .newsdetailPage .item2 .back-btn:hover::before {
        width: 100%; } }
    @media (max-width: 991px) {
      .newsdetailPage .item2 .back-btn {
        margin-top: 35px;
        width: 120px;
        height: 40px;
        line-height: 2.5; } }

.faqPage .item1 {
  padding-top: 105px; }
  @media (max-width: 767px) {
    .faqPage .item1 {
      padding-top: 73px; } }
  .faqPage .item1 #top-menu-ul2 {
    margin-top: 14px;
    position: relative;
    z-index: 1;
    padding: 0 50px; }
    @media (max-width: 767px) {
      .faqPage .item1 #top-menu-ul2 {
        padding: 0 27px; } }
    .faqPage .item1 #top-menu-ul2 .item_Menu {
      margin: 0 auto;
      overflow: hidden;
      height: 30px; }
    .faqPage .item1 #top-menu-ul2 .item_menu_Box {
      width: 100%;
      overflow: hidden;
      overflow-x: scroll;
      position: relative;
      text-align: center; }
      .faqPage .item1 #top-menu-ul2 .item_menu_Box .item_menu_list {
        white-space: nowrap !important;
        padding-bottom: 0px;
        text-align: center;
        font-size: 0px;
        display: inline-flex; }
      .faqPage .item1 #top-menu-ul2 .item_menu_Box li {
        height: 30px; }
      .faqPage .item1 #top-menu-ul2 .item_menu_Box li a {
        position: relative;
        display: block;
        line-height: 1;
        letter-spacing: 0.6px;
        font-size: 1.125rem;
        color: #000000; }
        @media (max-width: 767px) {
          .faqPage .item1 #top-menu-ul2 .item_menu_Box li a {
            padding: 7px 0 3px;
            margin: 0 50px 0 0; } }
        @media (min-width: 768px) {
          .faqPage .item1 #top-menu-ul2 .item_menu_Box li a {
            margin: 0 25px;
            padding: 7px 0; }
            .faqPage .item1 #top-menu-ul2 .item_menu_Box li a:hover {
              color: #1d2088; } }
      .faqPage .item1 #top-menu-ul2 .item_menu_Box li.active a {
        color: #1d2088; }

.faqPage .item2 {
  margin-top: 70px;
  padding-bottom: 160px; }
  @media (max-width: 767px) {
    .faqPage .item2 {
      margin-top: 40px;
      padding-bottom: 110px; } }
  .faqPage .item2 .faq-list {
    position: relative; }
    .faqPage .item2 .faq-list::before {
      position: absolute;
      content: '';
      right: -180px;
      top: -120px;
      background: url(../images/bg2.png) no-repeat center center/contain;
      width: 630px;
      height: 520px;
      pointer-events: none; }
      @media (max-width: 767px) {
        .faqPage .item2 .faq-list::before {
          width: 330px;
          height: 220px;
          top: 0;
          background-size: cover; } }
    .faqPage .item2 .faq-list::after {
      position: absolute;
      content: '';
      left: -170px;
      top: 90px;
      background: url(../images/bg2.png) no-repeat center center/contain;
      width: 630px;
      height: 520px;
      z-index: -1; }
      @media (max-width: 767px) {
        .faqPage .item2 .faq-list::after {
          width: 330px;
          height: 220px;
          background-size: cover;
          left: -200px;
          top: 210px; } }
  .faqPage .item2 .faq-list li .ti {
    background-color: #e6e6e6;
    position: relative;
    align-items: center; }
    .faqPage .item2 .faq-list li .ti::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 210px;
      height: 100%;
      background: url(../images/faq/faqbg.png);
      pointer-events: none; }
      @media (max-width: 767px) {
        .faqPage .item2 .faq-list li .ti::before {
          background-size: 215px;
          width: 105px; } }
  .faqPage .item2 .faq-list li .ti .des {
    color: #1d2088;
    font-weight: 500;
    font-size: 1.5rem;
    position: relative;
    top: 2px; }
    @media (max-width: 767px) {
      .faqPage .item2 .faq-list li .ti .des {
        font-size: 1.25rem;
        top: auto; } }
  .faqPage .item2 .faq-list li .ti .remore {
    color: #000; }
    .faqPage .item2 .faq-list li .ti .remore::before, .faqPage .item2 .faq-list li .ti .remore::after {
      background-color: #000; }
  .faqPage .item2 .faq-list li .ti .num {
    min-width: 61px;
    margin-right: 10px; }
    .faqPage .item2 .faq-list li .ti .num span {
      font-size: 3rem; }
      @media (max-width: 767px) {
        .faqPage .item2 .faq-list li .ti .num span {
          font-size: 1.875rem; } }
  @media (max-width: 767px) {
    .faqPage .item2 .faq-list li .card {
      width: calc(100% + 48px);
      left: -24px;
      position: relative;
      margin-top: -47px;
      padding-top: 85px; } }
  .faqPage .item2 .faq-list li .ti .remore {
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .faqPage .item2 .faq-list li .ti .remore {
        transform: none;
        top: 20px; } }

.bgcover {
  width: 100%;
  height: 0;
  background: 50%/cover; }

.mo {
  display: none; }
  @media (max-width: 767px) {
    .mo {
      display: block; } }

.pc {
  display: none; }
  @media (min-width: 768px) {
    .pc {
      display: block; } }

.container {
  padding: 0 16px;
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 767px) {
    .container {
      padding: 0 24px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1264px; } }

#top-menu-ul {
  padding: 0 50px;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #1d2088; }
  @media (max-width: 767px) {
    #top-menu-ul {
      margin: 0 auto;
      padding: 0 27px; } }
  @media (min-width: 768px) {
    #top-menu-ul {
      margin: 0 auto; } }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 60px; }
    @media (max-width: 767px) {
      #top-menu-ul .item_Menu {
        height: 55px; } }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: center; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    #top-menu-ul .item_menu_Box li {
      height: 60px; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li {
          height: 55px; }
          #top-menu-ul .item_menu_Box li:first-child {
            margin-left: 10px; } }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 2px;
      font-size: 1.25rem;
      color: #000000;
      transition: .4s ease; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 10px 19px 13px;
          margin: 0 20px 0 0; } }
      @media (min-width: 768px) {
        #top-menu-ul .item_menu_Box li a {
          margin: 0 15px;
          padding-top: 15px;
          padding-bottom: 16px;
          padding-left: 30px;
          padding-right: 30px; }
          #top-menu-ul .item_menu_Box li a:hover {
            color: #fff;
            background-color: #1d2088; }
            #top-menu-ul .item_menu_Box li a:hover::before {
              opacity: 1; } }
      #top-menu-ul .item_menu_Box li a::before {
        position: absolute;
        content: '';
        bottom: 5px;
        left: -8px;
        width: 95%;
        height: 2px;
        background-color: #bfbfbf;
        opacity: 0;
        transition: .4s; }
    #top-menu-ul .item_menu_Box li.active a {
      color: #fff;
      background-color: #1d2088; }
      #top-menu-ul .item_menu_Box li.active a::before {
        opacity: 1; }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translateY(-50%);
    top: 45%;
    z-index: 10;
    background-color: #000000;
    transition: none; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0; }
  .flex-direction-nav a.flex-next {
    background: url(../images/arrow_bt_r_unh.png) no-repeat center/13px;
    right: -2px; }
    @media (min-width: 768px) {
      .flex-direction-nav a.flex-next:hover {
        background: url(../images/arrow_r_bt.png) no-repeat center/12px; } }
    @media (max-width: 767px) {
      .flex-direction-nav a.flex-next {
        right: -13px;
        background: url(../images/arrow_r_bt.png) no-repeat center/11px; } }
  .flex-direction-nav a.flex-prev {
    background: url(../images/arrow_bt_l_unh.png) no-repeat center/13px;
    left: -2px; }
    @media (min-width: 768px) {
      .flex-direction-nav a.flex-prev:hover {
        background: url(../images/arrow_l_bt.png) no-repeat center/12px; } }
    @media (max-width: 767px) {
      .flex-direction-nav a.flex-prev {
        left: -13px;
        background: url(../images/arrow_l_bt.png) no-repeat center/11px; } }

.open_flexslider .flex-direction-nav {
  display: block; }

.sh-tibx .e-ti {
  font-size: 2.5rem;
  line-height: 1.45;
  letter-spacing: 3px;
  font-weight: 400;
  color: #000; }
  @media (max-width: 991px) {
    .sh-tibx .e-ti {
      font-size: 2.25rem;
      letter-spacing: 1px; } }

.sh-tibx .t-ti {
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: 2.4px;
  font-weight: 500;
  color: #1d2088; }
  @media (max-width: 991px) {
    .sh-tibx .t-ti {
      font-size: 1.25rem; } }

.sh-des {
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.8px;
  font-weight: 300;
  color: #000; }
  @media (max-width: 991px) {
    .sh-des {
      line-height: 1.75; } }

.news-list li {
  width: 100%;
  position: relative; }
  .news-list li a {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .news-list li a {
        display: block; } }
    @media (min-width: 768px) {
      .news-list li a:hover .read-btn {
        color: #1d2088; }
        .news-list li a:hover .read-btn::before {
          width: 100%; }
      .news-list li a:hover .img-bx .bgcover {
        transform: scale(1.15); } }
  .news-list li::before {
    position: absolute;
    content: '';
    width: 83%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e6e6e6;
    z-index: 0; }
    @media (max-width: 991px) {
      .news-list li::before {
        width: 100%; } }
  .news-list li:not(:last-child) {
    margin-bottom: 100px; }
    @media (max-width: 991px) {
      .news-list li:not(:last-child) {
        margin-bottom: 25px; } }
  @media (min-width: 992px) {
    .news-list li:nth-child(even) .img-bx {
      order: 2; }
    .news-list li:nth-child(even) .des-bx {
      order: 1;
      margin-left: 0;
      margin-right: 5%;
      margin-left: 11%;
      text-align: right; } }
  .news-list li .img-bx {
    position: relative;
    width: 41%;
    top: -40px;
    overflow: hidden; }
    @media (max-width: 991px) {
      .news-list li .img-bx {
        width: 100%;
        top: auto; } }
    .news-list li .img-bx .bgcover {
      padding-bottom: 315px;
      transition: .4s ease;
      background-size: cover !important; }
      @media (max-width: 991px) {
        .news-list li .img-bx .bgcover {
          padding-bottom: 62.5%; } }
  .news-list li .des-bx {
    width: 43%;
    margin-left: 5%; }
    @media (max-width: 991px) {
      .news-list li .des-bx {
        margin-left: 0;
        width: 100%;
        padding: 35px 15px;
        padding-bottom: 38px; } }
    .news-list li .des-bx .da {
      font-size: 1rem;
      line-height: 1.8;
      letter-spacing: 0.8px;
      font-weight: 400;
      color: #1d2088;
      margin-bottom: 20px; }
      @media (max-width: 991px) {
        .news-list li .des-bx .da {
          margin-bottom: 10px; } }
    .news-list li .des-bx .des {
      font-size: 1.5rem;
      line-height: 1.25;
      letter-spacing: 2.4px;
      font-weight: 500;
      color: #000;
      height: 60px;
      position: relative;
      overflow: hidden; }
      @media (max-width: 991px) {
        .news-list li .des-bx .des {
          font-size: 1.25rem;
          height: 50px;
          margin-bottom: 10px; } }
    .news-list li .des-bx .read-btn {
      font-size: 1rem;
      line-height: 1.88;
      letter-spacing: 0.8px;
      font-weight: 400;
      color: #000;
      border-bottom: 1px solid #000;
      padding: 10px 0;
      padding-bottom: 13px;
      position: relative;
      top: 42px;
      display: inline-block; }
      @media (max-width: 991px) {
        .news-list li .des-bx .read-btn {
          top: auto;
          padding: 5px 0;
          padding-bottom: 7px; } }
      .news-list li .des-bx .read-btn::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: #1d2088;
        transition: .5s ease; }

.sh-inban {
  width: 100%;
  position: relative; }
  .sh-inban .bgcover {
    padding-bottom: 20.8%;
    background-size: cover !important; }
    @media (max-width: 1199px) {
      .sh-inban .bgcover {
        padding-bottom: 300px; } }
    @media (max-width: 767px) {
      .sh-inban .bgcover {
        padding-bottom: 52%; } }
  .sh-inban .ban-ti {
    width: 100%;
    max-width: 1264px;
    margin: 0 auto;
    padding: 0 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .sh-inban .ban-ti .e-ti {
      font-size: 2.5rem;
      line-height: 1.45;
      letter-spacing: 4px;
      font-weight: 400;
      color: #000; }
      @media (max-width: 767px) {
        .sh-inban .ban-ti .e-ti {
          font-size: 2.25rem;
          letter-spacing: 1.5px; } }
    .sh-inban .ban-ti .t-ti {
      font-size: 1.5rem;
      line-height: 1.25;
      letter-spacing: 2.4px;
      font-weight: 500;
      color: #1d2088; }
      @media (max-width: 767px) {
        .sh-inban .ban-ti .t-ti {
          font-size: 1.25rem; } }

.sh-lti {
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 2.4px;
  font-weight: 500;
  color: #000; }
  @media (max-width: 767px) {
    .sh-lti {
      font-size: 1.25rem; } }
  .sh-lti span {
    padding-left: 17px;
    position: relative; }
    @media (max-width: 767px) {
      .sh-lti span {
        padding-left: 12px; } }
    .sh-lti span::before {
      position: absolute;
      content: '';
      left: 0;
      top: 3px;
      width: 1px;
      height: 83%;
      background-color: #000; }

.faq-list li:not(:last-child) {
  margin-bottom: 16px; }
  @media (max-width: 767px) {
    .faq-list li:not(:last-child) {
      margin-bottom: 23px; } }

.faq-list li .active.ti {
  box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.2); }
  @media (max-width: 767px) {
    .faq-list li .active.ti {
      box-shadow: 0px 2.5px 2.5px 0 rgba(0, 0, 0, 0.2); } }
  .faq-list li .active.ti .remore::before {
    height: 0; }

.faq-list li .ti {
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 35px;
  padding-right: 27px;
  max-width: 1126px;
  display: flex;
  align-items: baseline;
  background-color: #1d2088;
  z-index: 3;
  position: relative;
  transition: .4s linear; }
  @media (max-width: 767px) {
    .faq-list li .ti {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      padding-bottom: 12px; } }
  .faq-list li .ti .num {
    font-size: 1.125rem;
    line-height: 0.8;
    letter-spacing: 0px;
    font-weight: 300;
    color: #fff;
    min-width: 110px;
    margin-right: 13px; }
    @media (max-width: 767px) {
      .faq-list li .ti .num {
        margin-bottom: 15px; } }
    .faq-list li .ti .num span {
      font-size: 2.375rem;
      font-weight: 700;
      margin: 0 5px; }
      @media (max-width: 767px) {
        .faq-list li .ti .num span {
          font-size: 1.875rem; } }
  .faq-list li .ti .flex-bx {
    display: flex;
    position: relative;
    width: calc(100% - 110px); }
    @media (max-width: 767px) {
      .faq-list li .ti .flex-bx {
        width: 100%;
        position: static; } }
  .faq-list li .ti .des {
    font-size: 1.125rem;
    line-height: 1.4;
    letter-spacing: 0.9px;
    font-weight: 300;
    color: #fff;
    width: calc(97% - 115px);
    margin-right: 3%; }
    @media (max-width: 767px) {
      .faq-list li .ti .des {
        width: 100%;
        margin-right: 0; } }
  .faq-list li .ti .remore {
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0.8px;
    font-weight: 400;
    color: #fff;
    padding-right: 30px;
    position: absolute;
    top: -2px;
    right: 0;
    min-width: 115px; }
    @media (max-width: 767px) {
      .faq-list li .ti .remore {
        top: 20px;
        right: 20px;
        font-size: 0.8125rem;
        min-width: 80px;
        padding-right: 20px; } }
    .faq-list li .ti .remore::before {
      position: absolute;
      content: '';
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 16px;
      background-color: #fff;
      transition: .4s ease; }
      @media (max-width: 767px) {
        .faq-list li .ti .remore::before {
          height: 13px;
          width: 1px;
          right: 6px; } }
    .faq-list li .ti .remore::after {
      position: absolute;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 2px;
      background-color: #fff;
      transition: .4s ease; }
      @media (max-width: 767px) {
        .faq-list li .ti .remore::after {
          width: 13px;
          height: 1px; } }

.faq-list li .card {
  max-width: 1129px;
  margin-left: auto;
  background-color: #fff;
  padding-top: 90px;
  padding-bottom: 60px;
  padding-left: 8.8%;
  padding-right: 8.8%;
  display: none;
  z-index: 1;
  position: relative;
  margin-top: -35px; }
  @media (max-width: 767px) {
    .faq-list li .card {
      padding-top: 33px;
      padding-bottom: 45px;
      width: calc(100% + 48px);
      left: -24px;
      position: relative;
      margin-top: -47px;
      padding-top: 85px; } }

.in_fade {
  position: relative;
  transform: translateY(20px);
  transition: 1s ease;
  opacity: 0; }

.active.in_fade {
  opacity: 1;
  transform: translateY(0); }

.hiddendiv {
  display: none; }

.curcil {
  position: fixed;
  width: 24px;
  height: 24px;
  background: url(../images/mouse.png) no-repeat center center/cover;
  border-radius: 50%;
  z-index: 9999;
  transition: opacity .4s;
  animation: curani 4s linear infinite; }
  @media (max-width: 767px) {
    .curcil {
      display: none; } }

@keyframes curani {
  0% {
    transform: rotate(0); }
  0% {
    transform: rotate(360deg); } }
