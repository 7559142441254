.refuelPage{
    .sec-bx{
        position: relative;
        &::before{
            position: absolute;
            content: '';
            right: 0;
            bottom: 0;
            height: 100%;
            background: url(../images/bg1.png?1fea62b0);
            width: 195px;
            z-index: -1;
            @media (max-width:767px) {
                display: none;
            }
        }
        &::after{
            position: absolute;
            content: '';
            right: 186px;
            bottom: -3px;
            width: 320px;
            height: 170px;
            background: url(../images/bg1.png?1fea62b0);
            z-index: -1;
            @media (max-width:767px) {
                display: none;
            }
        }
    }
    .item1{
        padding-top: 120px;
        @media (max-width:767px) {
            padding-top: 75px;
        }

        .it1-bx{
            .sh-lti{
                @media (max-width:767px) {
                    margin-bottom: 15px;
                }
            }
            .it1-list{
                margin-top: 45px;
                display: flex;
                flex-wrap: wrap;
                @media (max-width:767px) {
                    max-width: 86%;
                    margin: 0 auto;
                    margin-top: 10px;
                }
                @media (max-width:370px) {
                    max-width: 100%;
                }
                li{
                    width: 23.5%;
                    display: flex;
                    align-items: center;
                    height: 80px;
                    justify-content: space-between;
                    padding-right: 15px;
                    padding-left: 21px;
                    padding-top: 8px;
                    @media (max-width:1199px) {
                        width: 47%;
                        margin-bottom: 15px;
                        &:nth-child(odd){
                            margin-right: 4%;
                        }
                    }
                    @media (max-width:767px) {
                        padding-top: 16px;
                        padding-bottom: 10px;
                        height: 90px;
                        display: block;
                        text-align: center;
                    }
                    @media (max-width:370px) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                    &:nth-child(1){
                        background-color: #6a50c8;
                    }
                    &:nth-child(2){
                        background-color: #f29f22;
                    }
                    &:nth-child(3){
                        background-color: #69bb4e;
                    }
                    &:nth-child(4){
                        background-color: #3dbae0;
                    }
                    .t{
                        @include fontset(18,2.3,1.8,500,#fff);
                        order: 1;
                        @media (max-width:767px) {
                            font-size: pxToEm(15);
                            line-height: 2;
                        }
                    }
                    .pric{
                        @include fontset(18,3.5,1.8,500,#fff);
                        @media (max-width:767px) {
                            font-size: pxToEm(15);
                            line-height: 1.3;
                        }
                        order: 2;
                        span{
                            @include fontset(38,1.8,.95,500,#fff);
                            @media (max-width:767px) {
                                font-size: pxToEm(31);
                                line-height: 1.1;
                                font-weight: 400;
                            }
                        }
                    }
                    @media (min-width:1200px) {
                        &:not(:last-child){
                            margin-right: 2%;
                        }
                    }
                   
                }
            }
            .oil-da{
                @include fontset(16,2,1.6,400,$hover);
            }
            .flex-bx{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 25px;
                @media (max-width:767px) {
                    margin-top: 0px;
                }
                .un{
                    @include fontset(16,2,1.6,400,$hover);
                }
            }
        }
    }
    .item2{
        margin-top: 115px;
        position: relative;
        @media (max-width:767px) {
            margin-top: 65px;
        }
        &::before{
            position: absolute;
            content: '';
            height: 70%;
            width: 100vw;
            left: 0;
            bottom: 52px;
            background-color: #e6e6e6;
            @media (max-width:767px) {
                display: none;
            }
        }
        .it2-bx{
            position: relative;
            .img-bx{
                position: absolute;
                left: 58.1%;
                bottom: 0;
                width: 45.3vw;
                @media (max-width:991px) {
                    position: relative;
                    left: auto;
                    bottom: auto;
                    width: 100%;
                }
                @media (max-width:450px) {
                    width: calc(100% + 48px);
                    left: -24px;
                }
                .bgcover{
                    padding-bottom: 380px;
                    background-size:  cover !important;
                    @media (max-width:767px) {
                        padding-bottom: 43.6%;
                    }
                }
            }
            .card{
                width: 58.1%;
                @media (max-width:991px) {
                    width: 100%;
                    padding-top: 40px;
                }
                @media (max-width:767px) {
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        width: calc(100% + 48px);
                        left: -24px;
                        top: 0;
                        height: calc(100% - 48px);
                        background-color: #e6e6e6;
                        z-index: -1;
                    }
                }
                .sh-lti{
                    margin-bottom: 92px;
                    @media (max-width:991px) {
                        margin-bottom: 24px;
                    }
                }
                .sh-des{
                    max-width: 590px;
                    width: 98%;
                    text-align: justify;
                    @media (max-width:1199px) {
                        width: 90%;
                    }
                    @media (max-width:991px) {
                        line-height: 1.5;
                    }
                }
                .des1{
                    margin-bottom: 40px;
                    @media (max-width:991px) {
                        margin-bottom: 30px;
                    }
                }
                .des2{
                    margin-bottom: 40px;
                }
                .bl-bx{
                    background-color: #1d2088;
                    padding: 28px 20px;
                    @media (max-width:767px) {
                        padding: 18px 15px;
                        width: calc(100% + 24px);
                    }
                    .des{
                        padding-left: 61px;
                        position: relative;
                        @include fontset(16,2,1.6,300,#fff);
                        @media (max-width:767px) {
                            line-height: 1.65;
                            padding-left: 41px;
                        }
                        img{
                            position: absolute;
                            width: 40px;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            @media (max-width:767px) {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .item3{
        padding: 120px 0;
        @media (max-width:767px) {
            padding: 70px 0;
            padding-bottom: 40px;
        }
        .it3-list{
            margin-top: 60px;
            display: flex;
            justify-content: space-between;
            @media (max-width:991px) {
                flex-wrap: wrap;
            }
            @media (max-width:767px) {
                margin-top: 25px;
            }
            li{
                text-align: center;
                @media (max-width:991px) {
                    width: 33%;
                    margin-bottom: 25px;
                }
                @media (max-width:767px) {
                    width: 50%;
                }
                &:not(:last-child){
                    // margin-right: 100px;
                }
                .sh-des{
                    margin-top: 15px;
                    @media (max-width:767px) {
                        margin-top: 2px;
                    }
                }
                .img-bx{
                    width: 90px;
                    height: 90px;
                    margin: 0 auto;
                    position: relative;
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
                &:nth-child(1){
                    .img-bx img{
                        width: 90px;
                        height: 90px;
                        @media (max-width:767px) {
                            width: 74px;
                            height: 74px;
                        }
                    }
                }
                &:nth-child(2){
                    .img-bx img{
                        width: 80px;
                        height: 32px;
                        @media (max-width:767px) {
                            width: 66px;
                            height: 26px;
                        }
                    }
                }
                &:nth-child(3){
                    .img-bx img{
                        width: 73px;
                        height: 31px;
                        @media (max-width:767px) {
                            width: 60px;
                            height: 26px;
                        }
                    }
                }
                &:nth-child(4){
                    .img-bx{
                        width: 225px;
                        @media (max-width:767px) {
                            width: 162px;
                        }
                        @media (max-width:350px) {
                            width: 140px;
                        }
                    }
                }
                &:nth-child(5){
                    .img-bx img{
                        width: 63px;
                        height: 61px;
                        @media (max-width:767px) {
                            width: 52px;
                            height: 50px;
                        }
                    }
                }
                &:nth-child(6){
                    .img-bx img{
                        width: 54px;
                        height: 53px;
                        @media (max-width:767px) {
                            width: 45px;
                            height: 43px;
                        }
                    }
                }
            }
        }
    }
}



.inspectionPage{
    .item1{
        padding-top: 120px;
        padding-bottom: 140px;
        position: relative;
        @media (max-width:991px) {
            padding-top: 75px;
            padding-bottom: 14%;
        }
        &::before{
            position: absolute;
            content: '';
            bottom: 0;
            right: 0;
            width: 150px;
            height: 140px;
            background: url(../images/bg1.png?1fea62b0);
            @media (max-width:767px) {
                width: 100%;
                height: 39px;
                background-size: cover;
            }
        }
        .it1-bx{
            margin-top: -35px;
            position: relative;
            height: 430px;
            display: flex;
            align-items: center;
            @media (max-width:991px) {
                display: block;
                margin-top: 15px;
            }
            &::before{
                position: absolute;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                left: -335px;
                background-color: #fff;
                width: 100vw;
                height: calc(100% - 150px);
                @media (max-width:991px) {
                    display: none;
                }
            }
            &::after{
                position: absolute;
                content: '';
                top: 50%;
                left: -335px;
                transform: translateY(-50%);
                width: 260px;
                height: calc(100% - 150px);
                background: url(../images/bg1.png?1fea62b0);
            }
            .img-bx{
                position: relative;
                left: 51.3%;
                width: 49.1vw;
                order: 2;
                @media (max-width:991px) {
                    left: -25px;
                    width: calc(100% + 50px);
                }
                .bgcover{
                    padding-bottom: 45.5%;
                    background-size:  cover !important;
                }   
            }
            .des-bx{
                position: absolute;
                width: 51.3%;
                padding-right: 2%;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                order: 1;
                @media (max-width:991px) {
                    position: relative;
                    width: 100%;
                    top: auto;
                    transform: none;
                    padding-right: 0;
                    margin-top: 40px;
                }
                .se-btn{
                    width: 148px;
                    height: 60px;
                    border: solid 1px #b5b5b5;
                    text-align: center;
                    display: block;
                    margin-top: 42px;
                    @include fontset(16,3.8,.8,300,#000);
                    position: relative;
                    @media (max-width:991px) {
                        margin-top: 32px;
                        height: 44px;
                        line-height: 2.8;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 3px;
                        background-color: $hover;
                        transition: .4s ease;
                    }
                    span{
                        position: relative;
                        padding-left: 32px;
                        img{
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 25px;
                        }
                    }
                    @media (min-width:768px) {
                        &:hover{
                            &::before{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .item2{
        padding-bottom: 95px;
        position: relative;
        @media (max-width:991px) {
            padding-top: 75px;
            padding-bottom: 70px;
        }
        .bg1{
            width: 505px;
            height: calc(100% - 165px);
            position: absolute;
            top: 0;
            right: 0;
            background-color: #fff;
            @media (max-width:767px) {
                display: none;
            }
        }
        .bg2{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 430px;
            height: 165px;
            background: url(../images/bg1.png?1fea62b0);
            @media (max-width:767px) {
                display: none;
            }
        }
        .it2-list{
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
            @media (max-width:767px) {
                margin-top: 35px;
                &::before{
                    position: absolute;
                    content: "";
                    top: -20px;
                    left: -25px;
                    width: 110px;
                    height: calc(100% + 20px);
                    background-color: #fff;
                    z-index: -1;
                }
                &::after{
                    position: absolute;
                    content: "";
                    top: 50%;
                    transform: translateY(-50%);
                    right: -25px;
                    width: calc(100% - 60px);
                    height: 70%;
                    background: url(../images/bg1.png?1fea62b0);
                    background-size: contain;
                    z-index: -1;
                }
            }
            li{
                width: 23%;
                margin-bottom: 60px;
                @media (max-width:767px) {
                    width: 46%;
                    margin-bottom: 30px;
                }
                .img-bx{
                    width: 95px;
                    height: 95px;
                    margin: 0 auto;   
                    @media (max-width:767px) {
                        width: 75px;
                        height: 75px;
                    }
                }
                .des{
                    text-align: center;
                    @include fontset(16,1.63,.4,300,#000);
                    position: relative;
                    padding-top: 16px;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 4px;
                        left: 0;
                        height: 1px;
                        width: 100%;
                        background-color: $hover;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        top: 1px;
                        left: 0;
                        height: 3px;
                        width: 56px;
                        background-color: $hover;
                        @media (max-width:767px) {
                            top: 2px;
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
    .item3{
        .container{
            position: relative;
            @media (max-width:991px){
                padding-bottom: 130px;
            }
            &::before{
                position: absolute;
                content: '';
                bottom: 0px;
                left: -165px;
                background: url(../images/inspection/6_img.png) no-repeat center center / contain;
                width: 379px;
                height: 157px;
                @media (max-width:991px){
                    left: 5px;
                    width: 254px;
                    height: 92px;
                }
            }
        }
        .it3-bx{
            margin-top: 25px;
            display: flex;
            @media (max-width:991px){
                display: block;
                margin-top: 15px;
            }
            .l{
                min-width: 253px;
                width: 253px;
                position: relative;
                @media (max-width:991px){
                    min-width: 100%;
                    width: 100%;
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: -350px;
                    top: 120px;
                    width: 478px;
                    height: 100%;
                    background: url(../images/bg1.png?1fea62b0);
                    z-index: -1;
                    @media (max-width:991px){
                        display: none;
                    }
                }
                .da-bx{
                    padding-bottom: 35px;
                    padding-top: 25px;
                    margin-bottom: 20px;
                    position: relative;
                    @media (max-width:991px){
                        padding-bottom: 14px;
                        padding-top: 15px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        left: -347px;
                        width: 600px;
                        height: 100%;
                        top: 0;
                        background-color: #e6e6e6;
                        z-index: -1;
                        @media (max-width:991px){
                            left: -25px;
                            width: calc(100% + 50px);
                            height: 100%;
                        }
                    }
                    .da{
                        display: flex;
                        @include fontset(16,1.8,1.6,300,#000);
                        i{
                            min-width: 105px;
                            width: 105px;
                            display: block;
                            position: relative;
                            &::before{
                                position: absolute;
                                content: '';
                                right: 10px;
                                width: 1px;
                                top: 6px;
                                height: 15px;
                                background-color: #000;
                            }
                        }
                    }
                }
                .img-obx{
                    @media (max-width:991px){
                        display: flex;
                        justify-content: space-between;
                        .img-bx{
                            width: 47%;
                        }
                    }
                }
                .bx1{
                    margin-bottom: 8px;
                }
            }
            .r{
                padding-left: 5.5%;
                width: calc(100% - 253px);
                padding-top: 25px;
                @media (max-width:991px){
                    width: 100%;
                    padding-left: 0;
                }
                .ti{
                    @include fontset(16,2,1.6,700,#000);
                    margin-bottom: 5px;
                    @media (max-width:991px){
                        letter-spacing: .5px;
                    }
                }
                .t-list{
                    padding-bottom: 32px;
                    margin-bottom: 32px;
                    border-bottom: 1px solid #e5e5e5;
                    @media (max-width:991px){
                        padding-bottom: 28px;
                        margin-bottom: 31px;
                    }
                    li{
                        display: flex;
                        align-items: center;
                        @media (max-width:991px){
                            align-items: baseline;
                        }
                        &:nth-child(4){
                            align-items: flex-start;
                            span{
                                position: relative;
                                top: 3px;
                            }
                        }
                        span{
                            display: block;
                            width: 32px;
                            min-width: 32px;
                            height: 32px;
                            background-color: $hover;
                            color: #fff;
                            margin-right: 15px;
                            text-align: center;
                            font-size: pxToEm(16);
                            border-radius: 50%;
                            line-height: 2;
                            @media (max-width:991px){
                                width: 27px;
                                min-width: 27px;
                                height: 27px;
                                line-height: 1.8;
                            }
                        }
                        i{
                            color: $hover;
                            font-weight: 700;
                        }
                        .des{
                            @include fontset(16,2.5,1.6,300,#000);
                            @media (max-width:991px){
                                line-height: 2.2;
                                letter-spacing: 0;
                            }
                        }
                    }
                }
                .bmark-ti{
                    span{
                        color: $hover;
                        margin-right: 5px;
                    }
                    @include fontset(16,2,1.2,300,#000);
                    @media (max-width:991px){
                        line-height: 1.8;
                    }
                }
                .flex-bx{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    @media (max-width:991px){
                        margin-top: 6px;
                    }
                    div{
                        border: 1px solid #a0a0a0;
                        padding: 13px 15px;
                        margin-bottom: 8px;
                        @media (max-width:991px){
                            padding: 7px 12px;
                            letter-spacing: 0;
                        }
                        &:not(:last-child){
                            margin-right: 15px;
                            @media (max-width:991px){
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
        .res-btn{
            width: 148px;
            height: 60px;
            border: solid 1px #b5b5b5;
            @include fontset(16,3.88,.8,300,#000);
            text-align: center;
            display: block;
            margin: 0 auto;
            margin-top: 30px;
            position: relative;
            transition: .4s ease;
            @media (max-width:991px){
                width: 120px;
                height: 40px;
                line-height: 2.5;
            }
            &::before{
                position: absolute;
                width: 0;
                height: 4px;
                left: 0;
                bottom: 0;
                content: '';
                background-color: $hover;
                transition: .4s ease;
            }
            @media (min-width:768px) {
                &:hover{
                    color: $hover;
                    &::before{
                        width: 100%;
                    }
                }
            }
            span{
                position: relative;
                padding-left: 25px;
                img{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 17px;
                }
            }
        }
    }
    .item4{
        padding-top: 120px;
        padding-bottom: 120px;
        @media (max-width:767px) {
            padding-top: 60px;
            padding-bottom: 80px;
        }
        .sh-lti{
            margin-bottom: 40px;
            text-align: center;
            @media (max-width:767px) {
                margin-bottom: 15px;
            }
        }
    }
}


.washPage{
    .item1{
        padding-top: 120px;
        padding-bottom: 120px;
        position: relative;
        @media (max-width:991px){
            padding: 80px 0;
            padding-bottom: 70px;
        }
        &::before{
            position: absolute;
            content: '';
            left: 0;
            bottom: -10px;
            width: 490px;
            height: 60%;
            background: url(../images/bg1.png?1fea62b0);
            z-index: -1;
            @media (max-width:767px) {
                display: none;
            }
        }
    }
    .it1-bx{
        display: flex;
        align-items: flex-end;
        margin-top: 50px;
        justify-content: space-between;
        @media (max-width:991px){
            display: block;
            margin-top: 12px;
        }
        .l{
            width: 48.7%;
            @media (max-width:991px){
                width: calc(100% + 50px);
                left: -25px;
                position: relative;
            }
            .bgcover{
                padding-bottom: 490px;
                background-size: cover !important;
                @media (max-width:991px){
                    padding-bottom: 82%;
                }
            }
        }
        .r{
            margin-bottom: 10px;
            width: 45.6%;
            padding-right: 3%;
            position: relative;
            @media (max-width:991px){
                width: 100%;
                padding-right: 0;
                padding-top: 40px;
            }
            &::before{
                position: absolute;
                content: '';
                bottom: 50px;
                width: 1500px;
                height: 100%;
                background-color: #fff;
                right: -360px;
                z-index: -1;
                @media (max-width:991px){
                    position: absolute;
                    top: 0;
                    left: -25px;
                    right: auto;
                    bottom: auto;
                    width: calc(100% + 50px);
                    height: 88%;
                    background: #e6e6e6;
                }
            }
            .sh-des{
                margin-bottom: 30px;
                @media (max-width:991px){
                    margin-bottom: 25px;
                }
            }
            .bl-bx{
                background-color: #1d2088;
                padding: 28px 20px;
                @media (max-width:767px) {
                    padding: 18px 15px;
                    margin-top: 35px;
                    width: calc(100% + 24px);
                }
                .des{
                    padding-left: 61px;
                    position: relative;
                    @include fontset(16,2,1.6,300,#fff);
                    @media (max-width:767px) {
                        line-height: 1.65;
                        padding-left: 41px;
                    }
                    img{
                        position: absolute;
                        width: 40px;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        @media (max-width:767px) {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}

.commodityPage{
    .item1{
        margin-top: 110px;
        position: relative;
        z-index: 5;
        @media (max-width:767px){
            margin-top: 70px;
        }
    }
    .item2{
        padding-bottom: 120px;
        position: relative;
        .bg1{
            position: absolute;
            top: -170px;
            right: 0;
            width: 585px;
            height: 95%;
            background: url(../images/bg1.png?1fea62b0);
            z-index: -1;
            @media (max-width:767px) {
                top: -130px;
                width: 45%;
                background-size: contain;
            }
        }
        @media (max-width:767px){
            padding-bottom: 65px;
        }
        .it2-bx{
            margin-top: 75px;
            @media (max-width:767px){
                margin-top: 50px;
            }
            .sh-lti{
                margin-bottom: 45px;
                @media (max-width:767px){
                    margin-bottom: 25px;
                }
            }
        }
    }
}