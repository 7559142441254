/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover{
    width: 100%;
    height: 0;
    background: 50%/cover;
}
.mo{
    display:none;
    @media (max-width:767px) {
        display: block;
    }
}
.pc{
    display: none;
    @media (min-width:768px) {
        display: block;
    }
}
.container{
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;
    @media (max-width:767px) {
        padding: 0 24px;
    }
    @media (min-width:576px) {
        max-width: 540px;
    }
    @media (min-width:768px) {
        max-width: 720px;
    }
    @media (min-width:992px) {
        max-width: 960px;
    }
    @media (min-width:1200px) {
        max-width: 1264px;
    }
}

// top-menu
#top-menu-ul{
   
    @media (max-width:767px) {
        margin:0 auto;
        padding: 0 27px;
    }
    @media (min-width:768px) {
        margin:0 auto ;
    }
    padding: 0 50px;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid $hover;
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 60px;
        @media (max-width:767px) {
            height: 55px;
        }
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:center;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
        }

        li{
            height: 60px;
            @media (max-width:767px) {
                height: 55px;
                &:first-child{
                    margin-left: 10px;
                }
            }
        }
        li a{
            position: relative;
            display: block;
            line-height: 1;
            letter-spacing: 2px;
            font-size: pxToEm(20);
            color: #000000;
            transition: .4s ease;
            @media (max-width:767px) {
                padding:10px 19px 13px;
                margin:0 20px 0 0; 
            }
            @media (min-width:768px) {
                margin:0 15px; 
                padding-top: 15px;
                padding-bottom: 16px;
                padding-left: 30px;
                padding-right: 30px;
                &:hover{
                    color:#fff;
                    background-color: $hover;
                    &::before{
                        opacity: 1;
                    }
                }
            }
            &::before{
                position: absolute;
                content: '';
                bottom: 5px;
                left: -8px;
                width: 95%;
                height: 2px;
                background-color: #bfbfbf;
                opacity: 0;
                transition: .4s;
            }
        }
        li.active a{
            color:#fff;
            background-color: $hover;
            &::before{
                opacity: 1;
            }
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:40px;   
        height: 40px;
        position: absolute;        
        transform: translateY(-50%);
        top:45%;
        z-index: 10;
        background-color: #000000;
        transition: none;
        &:after{
            position: absolute;
            top: 0;
        }
    }
    a.flex-next{
        background: url(../images/arrow_bt_r_unh.png) no-repeat center / 13px;
        right: -2px;
        @media (min-width:768px) {
            &:hover{
                background: url(../images/arrow_r_bt.png) no-repeat center / 12px;
            }
        }
        @media (max-width:767px) {
            right: -13px;
            background: url(../images/arrow_r_bt.png) no-repeat center / 11px;
        }
    }

    a.flex-prev{
        background: url(../images/arrow_bt_l_unh.png) no-repeat center / 13px;
        left:  -2px;
        @media (min-width:768px) {
            &:hover{
                background: url(../images/arrow_l_bt.png) no-repeat center / 12px;
            }
        }
        @media (max-width:767px) {
            left: -13px;
            background: url(../images/arrow_l_bt.png) no-repeat center / 11px;
        }
    }
}
    .open_flexslider{
    .flex-direction-nav{
        display: block;
    }
}

 ////   popup

//  .pop-act{
//     background: rgba(0, 0, 0, 0.8);
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     z-index: 999;
//     display: none;
//     .vo_signbg{
//         background: url(../images/qr.png) no-repeat;
//         position: relative;
//         width: 300px;
//         height: 300px;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         @media (max-width:992px) {
//             background-size:450px;
//             background-position: center center;
//             width: 450px; 
//         }
//         @media (max-width:576px) {
//             width: 320px;
//             background-size:320px;
//         }
//     }
//     .clos-btn{
//         width: 60px;
//         position: absolute;
//         top: -30px;
//         right: -90px;
//         border-radius: 99px;
//         display: block;
//         height: 20px;
//         cursor: pointer;
//         @media (max-width:576px) {
//             // top: 0px;
//             right: -20px;
//             width: 40px;
//             padding-bottom: 40px;
//         }
//         &:before{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//         &:after{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(-45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//     }
// }
// .show-pop{
//     display: block !important;
//     }


.sh-tibx{
    .e-ti{
        @include fontset(40,1.45,3,400,#000);
        @media (max-width:991px) {
            font-size: pxToEm(36);
            letter-spacing: 1px;
        }
    }
    .t-ti{
        @include fontset(24,1.25,2.4,500,$hover);
        @media (max-width:991px) {
            font-size: pxToEm(20);
        }
    }
}
.sh-des{
    @include fontset(16,1.75,.8,300,#000);
    @media (max-width:991px) {
        line-height: 1.75;
    }
}

.news-list{
    li{
        width: 100%;
        position: relative;
        a{
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            @media (max-width:991px) {
                display: block;
            }
            @media (min-width:768px) {
                &:hover{
                    .read-btn{
                        color: $hover;
                        &::before{
                            width: 100%;
                        }
                    }
                    .img-bx{
                        .bgcover{
                            transform: scale(1.15);
                        }
                    }
                }
            }
        }
        &::before{
            position: absolute;
            content: '';
            width: 83%;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #e6e6e6;
            z-index: 0;
            @media (max-width:991px) {
                width: 100%;
            }
        }
        &:not(:last-child){
            margin-bottom: 100px;
            @media (max-width:991px) {
                margin-bottom: 25px;
            }
        }
        @media (min-width:992px) {
            &:nth-child(even){
                .img-bx{
                    order: 2;
                }
                .des-bx{
                    order: 1;
                    margin-left: 0;
                    margin-right: 5%;
                    margin-left: 11%;
                    text-align: right;
                }
            }
        }
        .img-bx{
            position: relative;
            width: 41%;
            top: -40px;
            overflow: hidden;
            @media (max-width:991px) {
                width: 100%;
                top: auto;
            }
            .bgcover{
                padding-bottom: 315px;
                transition: .4s ease;
                background-size: cover !important;
                @media (max-width:991px) {
                    padding-bottom: 62.5%;
                }
            }
        }
        .des-bx{
            width: 43%;
            margin-left: 5%;
            @media (max-width:991px) {
                margin-left: 0;
                width: 100%;
                padding: 35px 15px;
                padding-bottom: 38px;
            }
            .da{
                @include fontset(16,1.8,.8,400,$hover);
                margin-bottom: 20px;
                @media (max-width:991px) {
                    margin-bottom: 10px;
                }
            }
            .des{
                @include fontset(24,1.25,2.4,500,#000);
                height: 60px;
                position: relative;
                overflow: hidden;
                @media (max-width:991px) {
                    font-size: pxToEm(20);
                    height: 50px;
                    margin-bottom: 10px;
                }
            }
            .read-btn{
                @include fontset(16,1.88,.8,400,#000);
                border-bottom: 1px solid #000;
                padding: 10px 0;
                padding-bottom: 13px;
                position: relative;
                top: 42px;
                display: inline-block;
                @media (max-width:991px) {
                    top: auto;
                    padding: 5px 0;
                    padding-bottom: 7px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 2px;
                    background-color: $hover;
                    transition: .5s ease;
                }
            }
        }
    }
}

.sh-inban{
    width: 100%;
    position: relative;
    .bgcover{
        // padding-bottom: 400px;
        padding-bottom: 20.8%;
        background-size: cover !important;
        @media (max-width:1199px) {
            padding-bottom: 300px;
        }
        @media (max-width:767px) {
            padding-bottom: 52%;
        }
    }
    .ban-ti{
        width: 100%;
        max-width: 1264px;
        margin: 0 auto;
        padding: 0 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .e-ti{
            @include fontset(40,1.45,4,400,#000);
            @media (max-width:767px) {
                font-size: pxToEm(36);
                letter-spacing: 1.5px;
            }
        }
        .t-ti{
            @include fontset(24,1.25,2.4,500,$hover);
            @media (max-width:767px) {
                font-size: pxToEm(20);
            }
        }
    }
}


.sh-lti{
    @include fontset(24,1.5,2.4,500,#000);
    @media (max-width:767px) {
        font-size: pxToEm(20);
    }
    span{
        padding-left: 17px;
        position: relative;
        @media (max-width:767px) {
            padding-left: 12px;
        }
        &::before{
            position: absolute;
            content: '';
            left: 0;
            top: 3px;
            width: 1px;
            height: 83%;
            background-color: #000;
        }
    }
}

.faq-list{
    li{
        &:not(:last-child){
            margin-bottom: 16px;
            @media (max-width:767px) {
                margin-bottom: 23px;
            }
        }
        .active.ti{
            box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.2);
            @media (max-width:767px) {
                box-shadow: 0px 2.5px 2.5px 0 rgba(0, 0, 0, 0.2);
            }
            .remore{
                &::before{
                    height: 0;
                }
            }
        }
        .ti{
            padding-top: 23px;
            padding-bottom: 23px;
            padding-left: 35px;
            padding-right: 27px;
            max-width: 1126px;
            display: flex;
            align-items: baseline;
            background-color: $hover;
            z-index: 3;
            position: relative;
            transition: .4s linear;
            @media (max-width:767px) {
                display: block;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 16px;
                padding-bottom: 12px;
            }
            .num{
                @include fontset(18,.8,0,300,#fff);
                min-width: 110px;
                margin-right: 13px;
                @media (max-width:767px) {
                    margin-bottom: 15px;
                }
                span{
                    font-size: pxToEm(38);
                    font-weight: 700;
                    margin: 0 5px;
                    @media (max-width:767px) {
                        font-size: pxToEm(30);
                    }
                }
            }
            .flex-bx{
                display: flex;
                position: relative;
                width: calc(100% - 110px);
                @media (max-width:767px) {
                    width: 100%;
                    position: static;
                }
            }
            .des{
                @include fontset(18,1.4,.9,300,#fff);
                width: calc(97% - 115px);
                margin-right: 3%;
                @media (max-width:767px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
            .remore{
                @include fontset(16,1.2,.8,400,#fff);
                padding-right: 30px;
                position: absolute;
                top: -2px;
                right: 0;
                min-width: 115px;
                @media (max-width:767px) {
                    top: 20px;
                    right: 20px;
                    font-size: pxToEm(13);
                    min-width: 80px;
                    padding-right: 20px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    right: 7px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 2px;
                    height: 16px;
                    background-color: #fff;
                    transition: .4s ease;
                    @media (max-width:767px) {
                        height: 13px;
                        width: 1px;
                        right: 6px;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 2px;
                    background-color: #fff;
                    transition: .4s ease;
                    @media (max-width:767px) {
                        width: 13px;
                        height: 1px;
                    }
                }
            }
        }
        .card{
            max-width: 1129px;
            margin-left: auto;
            background-color: #fff;
            padding-top: 90px;
            padding-bottom: 60px;
            padding-left: 8.8%;
            padding-right: 8.8%;
            display: none;
            z-index: 1;
            position: relative;
            margin-top: -35px;
            @media (max-width:767px) {
                padding-top: 33px;
                padding-bottom: 45px;
                width: calc(100% + 48px);
                left: -24px;
                position: relative;
                margin-top: -47px;
                padding-top: 85px;
            }
        }
    }
}


.in_fade{
    position: relative;
    transform: translateY(20px);
    transition: 1s ease;
    opacity: 0;
}
.active.in_fade{
    opacity: 1;
    transform: translateY(0);
}

.hiddendiv{
    display: none;
}


.curcil{
    position: fixed;
    width: 24px;
    height: 24px;
    background: url(../images/mouse.png) no-repeat center center / cover;
    border-radius: 50%;
    z-index: 9999;
    transition: opacity .4s;
    animation: curani 4s linear infinite;
    @media (max-width:767px) {
        display: none;
    }
}

@keyframes curani{
    0%{
        transform: rotate(0);
    }
    0%{
        transform: rotate(360deg);
    }
}
