/*----------------------header------------------------*/

.header_show {
    transition: .4S;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(#131314,0);
    z-index: 10;
    background-color: #fff;
    li {
        list-style: none;
    }
    &.active{
        background: rgba(#131314,1);
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 106px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: pxToEm(14);
    max-width: 1264px;
    margin: 0 auto;
    // background: url(../images/bavv.jpg) top left no-repeat;
}



.logo {
    width: 158px;
    height: 72px;
    background: url(../images/logo.png) center center no-repeat;
    background-size: contain;
    text-indent: -10000px;
    a {
        @include aLink();
    }
}

.member_btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 125px;
    z-index: 1;
    @include fontset(16,1.75,.8,700,#000);
    @media (max-width:767px) {
        font-size: pxToEm(12);
        line-height: 1.2;
        right: 80px;
        top: 56%;
        z-index: 1;
    }
    img{
        width: 24px;
        margin-right: 3px;
        position: relative;
        top: -3px;
        @media (max-width:767px) {
            width: 18px;
        }
    }
}

/*----------------------nav------------------------*/

.nav_box {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    z-index: 8;
    &::before{
        position: absolute;
        content: '';
        bottom: 30px;
        left: 0;
        width: 100%;
        height: 447px;
        background: url(../images/menu_pc.png) no-repeat center center / contain;
        @media (max-width:767px) {
            display: none;
        }
    }
    nav{
        max-width: 1264px;
        width: 100%;
        position: absolute;
        top: 43%;
        left: 55%;
        transform: translate(-50%,-50%);
        padding: 0 16px;
        @media (max-width:1200px) {
            top: 50%;
        }
    }
    ul {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        li{
            position: relative;
            width: 33.33333%;
            >a{
                @include fontset(24,.9,1,400,#000);
                position: relative;
                padding-left: 15px;
                border-left: 5px solid #000;
                text-align: left;
                @media (min-width: 768px) {
                    &:hover{
                        color: $hover;
                        border-color: $hover;
                    }
                }
            }
            .under-bx{
                padding-left: 15px;
                margin-top: 20px;
                margin-bottom: 55px;
                a{
                    @include fontset(16,1.3,.5,300,#000);
                    @media (min-width: 768px) {
                        &:hover{
                            color: $hover;
                        }
                    }
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                }
            }
        }
        li:not(:last-child){
            // margin-right: 1.5em;
        }
    }
    a{
        width: 100%;
        display: block;
      
        &:after{
            transition: .4s;
            position: relative;
            content:'';
            width: 0;
            height: 2px;
            background: $blue;
            bottom: -26px;
            left: 8px;
            opacity: 0;
        }
        &:hover{
            &:after{
                width: 77%;
                opacity: 1;
            }
            .en{
                opacity: 0;
            }
            .tw{
                opacity: 1;
            }
        }
    }
}


/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: block;
}

$bar-width: 31px;
$bar-height: 3px;
$bar-spacing: 10px;
$bar-color:#000;
.menu-wrapper {
    display: block;
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    z-index: 10;
}
.hamburger-menu{
    width: 12px;
}
.hamburger-menu:after{
    width: 24px;
}
.hamburger-menu:before {
    width: 30px;
}
.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    // width: $bar-width;
    height: $bar-height;
    @include border();
    @media (max-width:767px) {
        height: 2px;
    }
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: $bar-color;
    transition: all 0ms 100ms;
    @include border(99px);
    &.animate {
        background: rgba(255, 255, 255, 0);
        transition: all 0ms 0ms;
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $bar-color;
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $bar-color;
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu.animate:after {
    top: 0;
    width: 30px;
    transform: rotate(135deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

.hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(225deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}




@media (max-width:991px){
    $header_heright: 70px;

    .logo{
        width: 95px;
        height: 43px;
    }
    .header_show{
    }
    .header_box {
        width: 100%;
        height:  $header_heright;
        font-size: pxToEm(17);
        margin: 0 auto;
        padding: 0 24px;
    }
    .nav_box nav{
        width: 100%;
        margin: auto;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        margin-top: 9vw;
        height: calc(100% + 40px);
        &::before{
            position: absolute;
            content: '';
            bottom: 156px;
            left: 0;
            width: 100%;
            background: url(../images/menu_mb.png) no-repeat center center / contain;
            height: 169px;
        }
    }
    .nav_box {
        display: none;
        position: fixed;
        top:  $header_heright;
        left: 0;
        width: 100%;
        height: calc(100% + 100px);
        z-index: 11;
        overflow-y: scroll;
        
        ul {
            display: block;
            li {
                width: 100%;
                a {
                    padding: 20px 15px;
                    text-align: center;
                    text-decoration: none;
                    border-left: none;
                    font-size: pxToEm(20);
                    line-height: .8;
                    span{
                        padding-left: 15px;
                        position: relative;
                        &::before{
                            position: absolute;
                            content: '';
                            top: 2px;
                            left: 0;
                            height: 18px;
                            background-color: #000;
                            width: 2px;
                        }
                    }
                }
                .under-bx{
                    max-width: 100px;
                    margin: 0 auto;
                    text-align: left;
                    margin-top: 5px;
                    margin-bottom: 12px;
                    a{
                        padding: 5px 0;
                        text-align: left;
                    }
                }
            }
        }
        ul>li:not(:last-child) {
            margin-right: 0;
        }

    }
    .menu-wrapper {
        display: block;
    }

}


